import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import Swal from "sweetalert2/dist/sweetalert2";
import { FiMessageCircle, FiEye } from "react-icons/fi";
import { FEED, SELL_PRODUCT, VIEW_DET, VIEW_IMG } from "../../constants/url";
import { FaImage } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { formatDate } from "../../utils/dateUtils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Dialog,
  TextField,
  Paper,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  SUCC_ALERT_TITLE,
  SUCC_FEEDBACK,
} from "../../constants/alertMessage";
import ImageModal from "../../images/imageModal";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
class soldList extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      data: "",
      replay: "",
      images: [],
      open: false,
      open1: false,
      open2: false,
      feedId: "",
      name: "",
      customer: "",
      mobile_no: "",
      title: "",
      description: "",
      qty: "",
      unit: "",
      expDate: "",
      transpot_status: "",
      organic_status: "",
      safe_status: "",
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
    };

    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    const requestUrl = `${SELL_PRODUCT}/soldlist/`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          const k = 1;

          for (let i = 0; i < res.data.data.length; i++) {
            res.data.data[i].sno = k + i;
          }

          this.setState({ data: res.data.data });
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      )
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  addSerialNumbers(data) {
    return data.map((item, index) => ({
      ...item,
      sno: index + 1,
    }));
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    const requestUrl = `${SELL_PRODUCT}/search_sell_soldoutlist?query=${searchValue}&`;

    privateApiCall(requestUrl, "GET")
      .then((response) => {
        if (response.data && response.data.data) {
          const dataWithSerialNumbers = this.addSerialNumbers(
            response.data.data
          );
          this.setState({ data: dataWithSerialNumbers });
        } else {
          this.setState({ data: [] });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClose1 = () => {
    this.setState({ open1: false });
  };

  handleClose2 = () => {
    this.setState({ open2: false });
  };

  handleReplay = (event) => {
    event.preventDefault();

    const requestUrl = `${SELL_PRODUCT}/replayToSProduct/?_id=${this.state.feedId}&replay=${this.state.replay}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        if (res.data.status) {
          const requestUrl = `${SELL_PRODUCT}/expiredlist/`;
          privateApiCall(requestUrl, "GET")
            .then((res) => {
              const k = 1;
              for (let i = 0; i < res.data.data.length; i++) {
                res.data.data[i].sno = k + i;
              }

              this.setState({
                open2: false,
                replay: "",
                data: res.data.data,
              });

              Swal.fire({
                title: `${SUCC_ALERT_TITLE}`,
                text: `${SUCC_FEEDBACK}`,
                icon: "success",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ALERT_ICON_ERR}`,
                text: `${ERR_GETTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  handleChange(event) {
    this.setState({
      replay: event.target.value,
    }); // setValues({ ...values, error: false, [name]: e.target.value });
  }
  edit(cell, row) {
    const handleImageClick = () => {
      const imagesData = [row.image1, row.image2, row.image3, row.image4];

      const filteredImagesData = imagesData.filter(
        (image) => image !== undefined && image !== null && image !== ""
      );

      this.setState({ images: filteredImagesData });
      this.setState({ open: true });

      // Show the modal
    };

    const hasImageData = [row.image1, row.image2, row.image3, row.image4].some(
      (image) => image !== undefined && image !== null && image !== ""
    );
    const handleS = (e) => {
      this.setState({ feedId: row.id });
      this.setState({ open2: true });
    };
    const handlePOP = (e) => {
      const {
        customer,
        mobile_no,
        name,
        availableDate,
        expDate,
        description,
        title,
        qty,
        price,
        location,
        unit,
        freeDelivery,
        chargesAfter,
        safe_status,
        auction_status,
        organic_status,
        transpot_status,
      } = row;
      this.setState({
        customer: customer,
        mobile_no: mobile_no,
        name: name,
        availableDate: availableDate,
        expDate: expDate,
        description: description,
        freeDelivery: freeDelivery,
        chargesAfter: chargesAfter,
        title: title,
        qty: qty,
        price: price,
        location: location,
        unit: unit,
        safe_status,
        auction_status,
        organic_status,
        transpot_status,
      });
      this.setState({ open1: true });
    };

    return (
      <span>
        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductSoldoutList" &&
            item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#769A0F" size="20" />
                {/* <Tooltip id="2" /> */}
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#f9bc0b" size="20" />
              </i>
            )}
            <Tooltip id="2" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductSoldoutList" &&
            item.isDetailedInfo === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="1"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
            <Tooltip id="1" />
          </>
        )}

        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductSoldoutList" &&
            item.isShowImage === true
        ) && (
          <>
            {hasImageData && (
              <i
                className="ml-1"
                data-tooltip-id="4"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage onClick={handleImageClick} color="green" size="17" />
              </i>
            )}
            <Tooltip id="4" />
          </>
        )}
      </span>
    );
  }

  delete(cell, row) {
    return (
      <span>
        <button
          type="submit"
          className="btn btn-icon waves-effect waves-light btn-danger"
          data-toggle="modal"
          data-target={"#myModal" + row._id}
        >
          {" "}
          <i className="fa fa-remove"></i>{" "}
        </button>

        <div
          id={"myModal" + row._id}
          className="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form
              method="post"
              action={
                "/delete_category/" +
                row._id +
                "/?token=" +
                cookies.get("asia_page_token")
              }
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h5 className="modal-title" id="myModalLabel">
                    {STRING_CONSTANTS.WARNING}
                  </h5>
                </div>
                <div className="modal-body">
                  {" "}
                  {STRING_CONSTANTS.ARE_YOU_SURE}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CLOSE}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    {STRING_CONSTANTS.DELETE}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </span>
    );
  }

  render() {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="sellProducts" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">
                      {STRING_CONSTANTS.SELL_PROD_SOLD_LIST}
                    </h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.SELL_PRODUCTS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.SOLD_OUT_LIST}
                      </li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div
                      style={{
                        width: "98%",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "auto",
                          width: "200px",
                          marginBottom: "20px",
                          position: "relative",
                        }}
                      >
                        {this.state.permissions.some(
                          (item) =>
                            item.keyword === "sellProductSoldoutList" &&
                            item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder="Search..."
                              className="form-control"
                              style={{
                                width: "100%",
                                paddingLeft: "30px",
                                boxSizing: "border-box",
                              }}
                              onChange={(e) => {
                                this.handleSearch(e);
                                if (e.target.value) {
                                  e.target.nextSibling.style.display = "none";
                                } else {
                                  e.target.nextSibling.style.display = "block";
                                }
                              }}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                                pointerEvents: "none",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.CUSTOMER}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.MOBILE}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.TITLE}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.PRICE}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(this.state.data) &&
                              this.state.data.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left">{row.sno}</TableCell>
                                  <TableCell align="left">
                                    {row.customer}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.mobile_no}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.title}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.price}
                                  </TableCell>

                                  <TableCell align="left">
                                    {this.edit(null, row, null, index)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            <ImageModal
                              open={this.state.open}
                              handleClose={this.handleClose}
                              imageSrc={this.state.images}
                            />
                            <Dialog
                              open={this.state.open1}
                              onClose={this.handleClose1}
                              aria-labelledby="details-modal-title"
                              aria-describedby="details-modal-description"
                              style={{
                                maxHeight: "80vh",
                                overflowY: "auto",
                                marginTop: "100px",
                              }}
                              fullWidth
                              disableEnforceFocus
                              maxWidth="lg"
                            >
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="modal-content"
                              >
                                <Grid item xs={12} className="modal-header">
                                  <h5 id="details-modal-title">
                                    {" "}
                                    {STRING_CONSTANTS.PRODUCT_DETAILS}
                                  </h5>
                                  <Button
                                    onClick={this.handleClose1}
                                    color="inherit"
                                  >
                                    {" "}
                                    <span
                                      style={{ fontSize: "20px" }}
                                      aria-hidden="true"
                                    >
                                      &#128473;
                                    </span>
                                  </Button>
                                </Grid>
                                <Grid item xs={12} className="modal-body">
                                  <Table className="col-12">
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CUSTOMER_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.customer}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CUSTOMER_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.mobile_no}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.PRODUCT_TITLE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.title}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.DESCRIPTION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.description}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.UNIT}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.unit}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.QUANTITY}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.qty}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.PRICE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.price}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.LOCATION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.location}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.AVAIL_DATE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {formatDate(this.state.availableDate)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.EXPIRY_DATE}
                                      </TableCell>
                                      <TableCell
                                        className="text-dark text-right font-weight-normal"
                                        id="des"
                                      >
                                        {formatDate(this.state.expDate)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.FREE_DELEVERY}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.freeDelivery}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CHARGES_AFTER}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.chargesAfter}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.FEATURES}
                                      </TableCell>
                                      <TableCell
                                        className="text-dark text-right font-weight-normal"
                                        id="des"
                                      >
                                        <div className="row justify-content-end">
                                          {this.state.auction_status ===
                                            "1" && (
                                            <img
                                              className="img-responsive "
                                              src={"/assets/icons/bid_icon.png"}
                                              alt="user"
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                              }}
                                            />
                                          )}
                                          {this.state.safe_status === "1" &&
                                            this.state.organic_status ===
                                              "1" && (
                                              <img
                                                className="img-responsive ml-1"
                                                src={
                                                  "/assets/icons/organi_1.jpg"
                                                }
                                                alt="user"
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                              />
                                            )}

                                          {this.state.safe_status === "1" &&
                                            this.state.organic_status ===
                                              "0" && (
                                              <img
                                                className="img-responsive ml-1"
                                                src={
                                                  "/assets/icons/safe_icon.jpg"
                                                }
                                                alt="user"
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                              />
                                            )}

                                          {this.state.transpot_status ===
                                            "1" && (
                                            <img
                                              className="img-responsive ml-1"
                                              src={"/assets/icons/trucks.png"}
                                              alt="user"
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Grid>
                                <Grid item xs={12} className="modal-footer">
                                  <Button
                                    onClick={this.handleClose1}
                                    variant="outlined"
                                    color="error"
                                  >
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Dialog>
                            <Dialog
                              open={this.state.open2}
                              onClose={this.handleClose2}
                              maxWidth="lg"
                              fullWidth
                              centered
                              disableEnforceFocus
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "20px",
                                  borderRadius: "8px",
                                }}
                              >
                                <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                                <form onSubmit={this.handleReplay}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <div className="text-left text-dark mb-1">
                                        {STRING_CONSTANTS.MESSAGE}
                                      </div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={this.state.replay}
                                        placeholder={STRING_CONSTANTS.MESSAGE}
                                        onChange={this.handleChange}
                                        required
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={this.handleClose2}
                                      style={{ marginRight: "8px" }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#769A0F",
                                        color: "#fff",
                                      }}
                                    >
                                      {STRING_CONSTANTS.SEND}
                                    </Button>
                                  </Grid>
                                </form>
                              </div>
                            </Dialog>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default soldList;
