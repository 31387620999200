import { useState, useEffect, useCallback } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import {
  MAP_PERMISSIONS,
  GET_SUBSCRIPTION,
  GET_MOBILE_PERMISSIONS,
  GET_SUBBYID,
  MAP_MOB_PERMISSIONS,
} from "../../constants/url";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
} from "@mui/material";
import {
  ERR_GETTING,
  ERR_POSTING,
  SUCCESS_SAVE_PERMISSIONS,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const MobliePermissions = () => {
  const [subs, setSubs] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [subId, setSubId] = useState("");
  const [roleDetails, setRoleDetails] = useState();

  useEffect(() => {
    fetchSubscription();
    fetchPermissions();
  }, []);

  useEffect(() => {
    
    if (subId || subId===0) {
      fetchSubById();
    }
  }, [subId]);

  const fetchSubscription = async () => {
    try {
      const requestUrl = `${GET_SUBSCRIPTION}`;
      const response = await privateApiCall(requestUrl, "GET");
      setSubs(response.data.subscription);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchSubById = async () => {


    try {
      const requestUrl = `${GET_SUBBYID}/${subId}`;
      const response = await privateApiCall(requestUrl, "GET");
      setRoleDetails(response.data);
      console.log(response.data, "resoinse");

      setPermissions(response.data.permissions);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchPermissions = async () => {
    try {
      const requestUrl = `${GET_MOBILE_PERMISSIONS}`;
      const response = await privateApiCall(requestUrl, "GET");
      setPermissions(response.data.permissions);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const handleCheckboxChange = (keyword, property) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.keyword === keyword
          ? { ...permission, [property]: !permission[property] }
          : permission
      )
    );
  };

  const handleSubmit = useCallback(async (event) => {
    console.log(permissions,"permissions");
    
    const roleData = { subscription_id : subId, permissions: permissions };
    event.preventDefault();
    const requestUrl = `${MAP_MOB_PERMISSIONS}`;
    try {
      const updatedPermission = await privateApiCall(requestUrl, "POST", roleData);

      localStorage.setItem("role", JSON.stringify(updatedPermission.data));
      localStorage.setItem("mobilepermission", JSON.stringify(updatedPermission.data));
      let localRole = JSON.parse(localStorage.getItem("role"));
      console.log(localRole.name);
      toast.success(`${SUCCESS_SAVE_PERMISSIONS}`, { transition: Bounce });
    } catch (err) {
      toast.error(`${ERR_POSTING}`, { transition: Bounce });
    }
  });

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.MOBILE_PERMISSON}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.MOBILE_PERMISSON}
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="role-label">
                      {STRING_CONSTANTS.SELECT_SUB}
                    </InputLabel>
                    <Select
                      labelId="role-label"
                      value={subId}
                      onChange={(e) => {
                        
                        setSubId(e.target.value);
                      }}
                      required
                    >
                      <MenuItem value="">
                        <em>{STRING_CONSTANTS.SELECT_SUB}</em>
                      </MenuItem>
                      {subs
                        .sort((a, b) => a.subscription_id - b.subscription_id)
                        .map((sub) => (
                          <MenuItem
                            key={sub.subscription_id}
                            value={sub.subscription_id}
                          >
                            {sub.name}
                          </MenuItem>
                        ))}
                    </Select>

                    <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                      <Link to="/dashboard">
                        {" "}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ mt: 2, mr: 1 }}
                        >
                          {" "}
                          {STRING_CONSTANTS.CANCLE}{" "}
                        </Button>
                      </Link>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="success"
                        sx={{ mt: 2 }}
                        style={{ backgroundColor: "#769A0F" }}
                      >
                        {" "}
                        {STRING_CONSTANTS.SAVE}{" "}
                      </Button>
                    </Grid>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{STRING_CONSTANTS.PERMISSON}</TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW}</TableCell>
                          <TableCell>{STRING_CONSTANTS.CREATE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.EDIT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.DELETE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.NAVIGATE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SEARCH}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SHOW_CART}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NOTIFICATION_COUNT}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_NOTIFICATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.CURRENT_LOCATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CLICK_HERE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_BUY_PAGE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_PROFILE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MYADS}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.SHARE}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.CUSTOMER_INFO}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_SELLER_INFO}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_SELLER_PROFILE}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.SHOW_IMAGE}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.DETAILED_INFO}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.DELETE_SINGLE}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.DELETE_ALL}</TableCell>
                          <TableCell>{STRING_CONSTANTS.ADD_COUPON}</TableCell>
                          <TableCell>{STRING_CONSTANTS.PAY}</TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_BIDDERS}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_FEEDBACK}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_BUYER}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_SELLER_LOCATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CALL}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CHAT_PAGE}
                          </TableCell>

                          <TableCell>{STRING_CONSTANTS.SELLER_INFO}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_UPDATE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_IMAGE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_REFER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MYACCOUNT}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MYACTIONS}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_BUSSINESSPROFILE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_FINDNEARBY}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_WEATHER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_AGROCTOR}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_LANGUAGE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_PRIVACY}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_TERMSANDCONDITION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_DISCLAMER}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.NAVIGATE_FAQ}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_DELETE_ACCOUNT}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_LOGOUT}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_APP_VERSION}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_MOBILE}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.SHARE_WHATSAPP}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.SHARE_MESSAGE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_WHATSAPP}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MESSAGE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_EARNED_COINS}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MANAGE_COINS}
                          </TableCell>

                          <TableCell>{STRING_CONSTANTS.VIEW_SUB}</TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_REF}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_PURCHASED}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_COINS}</TableCell>

                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_BUY_COINS}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_SUB_PLAN}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_SUB_VALIDITY}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.NAVIGATE_SUB}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_BIDS_NUMBER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MY_BIDS}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_OFFER_NUMBER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_MY_OFFER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_ORDERS}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_ALL_PAYMENTS}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.CREATE_LOGO}</TableCell>
                          <TableCell>{STRING_CONSTANTS.EDIT_LOGO}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.SEARCH_BUSSINESSTYPE}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_BUSSINESS}
                          </TableCell>

                          <TableCell>{STRING_CONSTANTS.VIEW_LOGO}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_BUSSINESS_LOCATION}
                          </TableCell>

                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CALL_BUSINESS}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.VIEW_TEMP}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_PRECEPITATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_HUMIDITY}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_WIND_SPEED}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.SELECT}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_VOLUNTEER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_ORG_REGISTER}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.CREATE_IMAGE}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_CALCULATION}
                          </TableCell>
                          <TableCell>{STRING_CONSTANTS.ACCEPT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.REJECT}</TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_SELLER_LOCATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CALL_SELLER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_SELLER_INFO}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_BUYER_LOCATION}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.NAVIGATE_CALL_BUYER}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_BUYER_INFO}
                          </TableCell>
                          <TableCell>
                            {STRING_CONSTANTS.VIEW_PERIODANDVALIDITY}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissions.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isView") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isView}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isView"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isCreate") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isCreate}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isCreate"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isEdit") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isEdit}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isEdit"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDelete") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDelete}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDelete"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigate") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigate}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigate"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isSearch") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSearch}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSearch"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isShowCart") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShowCart}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShowCart"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNotificationCount") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNotificationCount}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNotificationCount"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateNotification") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateNotification}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateNotification"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isViewCurrentLocation") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewCurrentLocation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewCurrentLocation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateClickHere") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateClickHere}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateClickHere"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateBuyPage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateBuyPage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateBuyPage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateProfile") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateProfile}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateProfile"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateMyads") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMyads}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMyads"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isShare") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDiaspora}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShare"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isCustomerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isCustomerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isCustomerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isNavigateSellerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateSellerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateSellerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateSellerProfile"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateSellerProfile}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateSellerProfile"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isShowImage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShowImage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShowImage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDetailedInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDetailedInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDetailedInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isDeleteSingle") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDeleteSingle}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDeleteSingle"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isDeleteAll") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isDeleteAll}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isDeleteAll"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isAddCoupen") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAddCoupen}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAddCoupen"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isPay") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isPay}
                                  onChange={(e) => {
                                    handleCheckboxChange(item.keyword, "isPay");
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewBidders") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewBidders}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewBidders"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewFeedBack") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewFeedBack}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewFeedBack"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isStatus") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isStatus}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isStatus"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewBuyer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewBuyer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewBuyer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateCall") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateCall}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateCall"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateChatPage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateChatPage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateChatPage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isSellerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSellerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSellerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateUpdate") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateUpdate}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateUpdate"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateImage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateImage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateImage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateRefer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateRefer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateRefer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateMyAccount") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMyAccount}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMyAccount"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateMyAction") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMyAction}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMyAction"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateBussinessProfile"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateBussinessProfile}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateBussinessProfile"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateFindNearBy") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateFindNearBy}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateFindNearBy"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateWeather") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateWeather}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateWeather"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateAgroter") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateAgroter}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateAgroter"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateLanguage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateLanguage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateLanguage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigatePrivacy") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigatePrivacy}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigatePrivacy"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateTermsAndCondition"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateTermsAndCondition}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateTermsAndCondition"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateDisclamer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateDisclamer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateDisclamer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateFAQ") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateFAQ}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateFAQ"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateDeleteAccount"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateDeleteAccount}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateDeleteAccount"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateLogout") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateLogout}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateLogout"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewAppVersion") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewAppVersion}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewAppVersion"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewMobile") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewMobile}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewMobile"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isShareWhatsapp") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShareWhatsapp}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShareWhatsapp"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isShareMessage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShareMessage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShareMessage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateWhatsapp") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateWhatsapp}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateWhatsapp"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateMessage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMessage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMessage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewEarnedCoins") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewEarnedCoins}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewEarnedCoins"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateManageCoins") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateManageCoins}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateManageCoins"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewSub") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewSub}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewSub"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewReferal") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewReferal}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewReferal"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewPurchased") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewPurchased}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewPurchased"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewCoins") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewCoins}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewCoins"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateBuyCoins") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateBuyCoins}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateBuyCoins"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewSubPlan") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewSubPlan}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewSubPlan"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewSubValidity") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewSubValidity}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewSubValidity"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateSub") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateSub}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateSub"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewBidsNumbers") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewBidsNumbers}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewBidsNumbers"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateMyBids") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMyBids}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMyBids"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewOffersNumbers") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewOffersNumbers}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewOffersNumbers"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateMyoffer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateMyoffer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateMyoffer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateOrders") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateOrders}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateOrders"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateAllPayments") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateAllPayments}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateAllPayments"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewCoins") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewCoins}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewCoins"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isCreateLogo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isCreateLogo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isCreateLogo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isEditLogo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isEditLogo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isEditLogo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isSearchBusinessType") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSearchBusinessType}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSearchBusinessType"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewBusiness") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewBusiness}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewBusiness"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewLogo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewLogo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewLogo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateBusinessLocation"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateBusinessLocation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateBusinessLocation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateCallBusiness") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateCallBusiness}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateCallBusiness"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewTemp") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewTemp}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewTemp"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewPrecipitation") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewPrecipitation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewPrecipitation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewHumidity") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewHumidity}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewHumidity"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewWindSpeed") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewWindSpeed}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewWindSpeed"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isSelect") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSelect}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSelect"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateVolunteer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateVolunteer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateVolunteer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateOrgRegister") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateOrgRegister}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateOrgRegister"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isCreateImage") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isCreateImage}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isCreateImage"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewCalcuation") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewCalcuation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewCalcuation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isAccept") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAccept}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAccept"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isReject") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isReject}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isReject"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateSellerLocation"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateSellerLocation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateSellerLocation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateCallSeller") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateCallSeller}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateCallSeller"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewSellerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewSellerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewSellerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isNavigateBuyerLocation"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateBuyerLocation}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateBuyerLocation"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isNavigateCallBuyer") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isNavigateCallBuyer}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isNavigateCallBuyer"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isViewBuyerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewBuyerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewBuyerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty(
                                "isViewPeriodAndValidity"
                              ) ? (
                                <input
                                  type="checkbox"
                                  checked={item.isViewPeriodAndValidity}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isViewPeriodAndValidity"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Link to="/dashboard">
                      {" "}
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2, mr: 1 }}
                      >
                        {" "}
                        {STRING_CONSTANTS.CANCLE}{" "}
                      </Button>
                    </Link>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="success"
                      sx={{ mt: 2 }}
                      style={{ backgroundColor: "#769A0F" }}
                    >
                      {" "}
                      {STRING_CONSTANTS.SAVE}{" "}
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobliePermissions;
