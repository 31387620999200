import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { io } from "socket.io-client";
import { ADD_EMPLOYEE } from "../../../constants/url";
import { privateApiCall } from "../../../api/privateApi";
import {API} from '../../../config'
// const socket = io('http://localhost:3002');
const ChatBoxModal = ({ open, handleClose, chatId, offer, chatHistory }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(chatHistory || []);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const socket=useRef()
  
  useEffect(() => {
     
      socket.current = io(API);
      socket.current.emit("new-user-add", offer.user_id);
  
      socket.current.on("connect", () => {
        console.log("Socket connected:", socket.current.id);
      });
  
      socket.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
  
      socket.current.on("receiver-msg", (data) => {
        
        setMessages((prevMessages) => [...prevMessages, data]);
      });
    
  
    return () => {
      if (socket.current) {
        socket.current.disconnect(); // Disconnect the socket on cleanup
      }
    };
  }, [open, chatId, offer.org_id, offer.user_id]);
  

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleSend = async () => {
    const newMessage = {
      ChatId: chatId,
      senderId: offer.org_id,
      receiverId: offer.user_id,  
      productId: offer.product_id,
      text: message,
    };
    console.log(newMessage,"new message")
    socket.current.emit("send-message", newMessage);
    try {
      const requestUrl = `${ADD_EMPLOYEE}/admsg`;
      await privateApiCall(requestUrl, "POST", newMessage)
      setMessages((prevMessages) => [...prevMessages, newMessage]);  
      setMessage(""); 
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="chat-modal-title"
      aria-describedby="chat-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 500,
          bgcolor: "background.paper",
          p: 2,
          borderRadius: "8px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Chat with {offer.seller_name}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Chat History */}
        <Box
          sx={{
            height: 300,
            overflowY: "auto",
            p: 2,
            bgcolor: "#f1f1f1",
            borderRadius: "8px",
            marginBottom: 2,
          }}
        >
          {messages && messages.length > 0 ? (
            messages.map((msg, index) => (
              <Box
                key={index}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent:
                    msg.senderId === offer.org_id ? "flex-end" : "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    bgcolor:
                      msg.senderId === offer.org_id ? "#d1ffd6" : "#f0f0f0",
                    borderRadius: "8px",
                    p: 1,
                    maxWidth: "70%",
                  }}
                >
                  {msg.text}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No messages yet</Typography>
          )}
          <div ref={messagesEndRef} />
        </Box>

        {/* Message Input */}
        <TextField
          variant="outlined"
          multiline
          rows={2}
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
        />

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button onClick={handleSend} variant="contained">
            Send
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatBoxModal;
