import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { ADMIN_ADD_USER_URL } from "../../constants/url";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  ERR_ALERT_TITLE,
  ERR_POSTING,
  SUCC_ADD_CUS,
  SUCC_ALERT_TITLE,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const AddCustomer = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    error: "",
    header: `${STRING_CONSTANTS.ADD_CUSTOMER}`,
    success: false,
    crops: [],
    message: "",
    imageName: "",
    type_id: "",
    type: "add",
    image: "",
    removed: false,
  });
  const { name, mobile, email, header } = values;
  useEffect(() => {}, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const requestUrl = `${ADMIN_ADD_USER_URL}?mobile_no=${mobile}&refCode=&name=${name}&email=${email}&country_code=`;
    privateApiCall(requestUrl, "GET").then(
      (res) => {
        if (res.data.status) {
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_ADD_CUS}`,
            icon: `${ ALERT_ICON_SUCC }`,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              document.location = "/admin/customer";
            }
          });
        } else {
          Swal.fire({
            title: `${ ERR_ALERT_TITLE }`,
            text: res.data.message,
            icon: `${ ALERT_ICON_ERR }`,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ ALERT_ICON_ERR }`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      }
    );
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };
  const dataTable = () => {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="profiles" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {" "}
                          <Link to="/dashboard">
                            {STRING_CONSTANTS.DASHBOARD}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {STRING_CONSTANTS.ADD_CUSTOMER}
                        </li>
                      </ol>
                    </div>
                    <h4 className="page-title">
                      {STRING_CONSTANTS.ADD_CUSTOMER}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box" style={{ paddingBottom: "50px" }}>
                    <div className="form-group form-inline">
                      <label>{header}</label>
                    </div>

                    <form onSubmit={onSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                          id="name"
                          name="name"
                          onChange={handleChange("name")}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={mobile}
                          placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                          id="mobile"
                          name="mobile"
                          onChange={handleChange("mobile")}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={email}
                          placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                          id="email"
                          name="email"
                          onChange={handleChange("email")}
                          required
                        />
                      </div>

                      <button type="submit" className="btn btn-primary">
                        {STRING_CONSTANTS.SUBMIT}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <React.Fragment>{dataTable()}</React.Fragment>;
};

export default AddCustomer;
