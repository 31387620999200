import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import {
  getCallSupportTeam,
  editCallSupportTeam,
  updateCallSupportTeamStatus,
  createCallSupportTeam,
  searchCallSupportTeam
} from "../actions/callSupport";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { FiEdit2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Button,
  Modal,
  TextField,
} from "@mui/material"; // Import MUI components
import { Tooltip } from "react-tooltip";
import { CONFIRM,ERROR,ERR, ERR_ALERT_TITLE, ERR_GETTING, ALERT_BUTTON_OK, ALERT_BUTTON_YES, RESTORE_ACC, REMOVE_ACC } from "../constants/alertMessage";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const CallSupport = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [callSupportId, setcallSupportId] = useState(null);
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    role: "",
    header: "",
    team: [],
    type: "",
    _id: "",
    success: false,
    removed: false,
    loading: false,
  });
  const { team, role, email, password, mobile, name, type, _id } =
    values;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getCallSupportTeam().then((data) => {
      if (data.error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      } else {
        setValues({
          ...values,
          role: "",
          name: "",
          email: "",
          password: "",
          mobile: "",
          team: data.team,
          header: "Add Call Support Team",
          _id: "",
          type: "add",
        });
      }
    });
  };


  const handleSearch = async (e) => {
    const searchValue = e.target.value;
    try {
      const response = await searchCallSupportTeam(searchValue);
  
      if (response.status && response.team) {
        setValues((prevState) => ({
          ...prevState,
          team: response.team, 
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          team: [], 
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERROR}`,
        text: error.message ,
        icon: `${ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${CONFIRM}`,
      });
    }
  };
  

  const handleEditClick = (row) => {
    setValues({
      ...values,
      error: false,
      name: row.name,
      mobile: row.mobile,
      role: row.role,
      email: row.email,
      password: row.password,
      header: "Edit Call Support Team",
      type: "edit",
      _id: row._id,
    });
    setcallSupportId(row._id);
    setShow(true);
  };
  const handleClearData = () => {
    setValues({
      ...values,
      role: "",
      name: "",
      email: "",
      password: "",
      mobile: "",
    });
    setShow(true);
    setcallSupportId(null);
  };

  const handleStatusClick = (row) => {
    const newStatus = !row.approved;
    const confirmMessage = newStatus
      ? `${RESTORE_ACC} ${row.name}?`
      : `${REMOVE_ACC} ${row.name}?`;

    Swal.fire({
      title: confirmMessage,
      text: "",
      icon: "question",
      allowOutsideClick: false,
      confirmButtonText: `${ALERT_BUTTON_YES}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        updateCallSupportTeamStatus(row._id, newStatus).then((data) => {
          if (data.error) {
            setValues({ ...values, error: data.error, loading: false });
          } else {
            loadData();
          }
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setValues({ ...values, loading: true, error: false });
    const chapter = { name, role, email, mobile, password };
    if (type === "add") {
      createCallSupportTeam(chapter).then((data) => {
        if (data.error) {
          Swal.fire({
            title: data.message,
            text: "",
            icon: "error",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
        } else {
          setValues({ ...values, error: false });
          Swal.fire({
            title: data.message,
            text: "",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
          handleClose(false);
          loadData();
        }
      });
    } else if (type === "edit") {
      editCallSupportTeam(_id, chapter).then((data) => {
        if (data.error) {
          Swal.fire({
            title: data.message,
            text: "",
            icon: "error",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
        } else {
          setValues({ ...values, error: false });
          Swal.fire({
            title: data.message,
            text: "",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText:`${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
          handleClose(false);
          loadData();
        }
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.CALL_SUPPORT}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.CALL_SUPPORT}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary "
                        onClick={(e) => handleClearData()}
                        style={{ marginRight: '10px' }}
                      >
                        <i className="fa fa-plus"></i> {STRING_CONSTANTS.ADD_CALL_SUPPORT}
                      </button>
                      <div style={{ position: 'relative', marginLeft: 'auto', }}>
                        <input
                          type="text"
                          placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                          className="form-control"
                          style={{ width: '200px', paddingLeft: '35px', boxSizing: 'border-box', marginLeft: "auto" }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === '') {
                              e.target.nextElementSibling.style.display = 'inline-block';
                            } else {
                              e.target.nextElementSibling.style.display = 'none';
                            }
                          }}
                       />
                        <i
                          className="fas fa-search"
                          style={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#aaa',
                          }}
                        ></i>
                      </div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ minWidth: 100 }}>
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.ROLE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.STATUS}
                            </TableCell>
                            <TableCell style={{ minWidth: 200 }}>
                            {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {team.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              <TableCell>{row.mobile}</TableCell>
                              <TableCell>{row.role}</TableCell>
                              <TableCell>
                                {" "}
                                {row.approved ? (
                                  <span className="btn-sm btn-success ">
                                    {STRING_CONSTANTS.ACTIVE}
                                  </span>
                                ) : (
                                  <span className="btn-sm btn-danger">
                                    {STRING_CONSTANTS.INACTIVE}
                                  </span>
                                )}{" "}
                              </TableCell>
                              <TableCell>
                                <FiEdit2
                                  size="18px"
                                  color="#0978F2"
                                  data-tooltip-id="1"
                                  data-tooltip-content="Edit"
                                  onClick={() => handleEditClick(row)}
                                />
                                <Tooltip id="1" />
                                {row.approved ? (
                                  <FaRegThumbsDown
                                    size="18px"
                                    className="ml-3"
                                    color="red"
                                    data-tooltip-id="3"
                                    data-tooltip-content="InActive"
                                    onClick={() => handleStatusClick(row)}
                                  />
                                ) : (
                                  <FaRegThumbsUp
                                    size="18px"
                                    className="ml-3"
                                    color="green"
                                    data-tooltip-id="2"
                                    data-tooltip-content="Active"
                                    onClick={() => handleStatusClick(row)}
                                  />
                                )}
                              </TableCell>
                              <Tooltip id="2" />
                              <Tooltip id="3" />
                            </TableRow>
                          ))}
                          <Modal
                            open={show}
                            onClose={handleClose}
                            aria-labelledby="add-call-support-modal-title"
                            aria-describedby="add-call-support-modal-description"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "900px",
                                height: "300px",
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "8px",
                                boxShadow: 24,
                                outline: "none",
                              }}

                            >

                              <h3  >
                                {callSupportId
                                  ? `${STRING_CONSTANTS.EDIT_CALL_SUPPORT}`
                                  : `${STRING_CONSTANTS.ADD_CALL_SUPPORT}`}
                              </h3>

                              <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Name"
                                      value={name}
                                      placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                                      onChange={handleChange("name")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Email ID"
                                      value={email}
                                      placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                                      onChange={handleChange("email")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Mobile No"
                                      value={mobile}
                                      placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                                      onChange={handleChange("mobile")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Role"
                                      value={role}
                                      placeholder={STRING_CONSTANTS.ROLE_PLACEHOLDER}
                                      onChange={handleChange("role")}
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      label="Password"
                                      value={password}
                                      placeholder={STRING_CONSTANTS.PASSWORD_PLACEHOLDER}
                                      onChange={handleChange("password")}
                                      required
                                      fullWidth
                                      type="password"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="flex-end"
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#769A0F",
                                        color: "#fff",
                                      }}
                                    >
                                      {callSupportId
                                        ? `${STRING_CONSTANTS.UPDATE}`
                                        : `${STRING_CONSTANTS.SUBMIT}`}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </Box>
                          </Modal>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallSupport;
