import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashBoard.jsx";
import TermsAndCondition from "../pages/termsAndCondition.jsx";
import Disclaimer from "../pages/disclaimer.jsx";
import PrivacyPolicy from "../pages/privacyPolicy.jsx";
import CallSupport from "../pages/callSupport.jsx";
import ViewProductDetails from "../pages/viewProductDetails.jsx";
import Survey from "../pages/survey.jsx";
import Orders from "../pages/orders.jsx";
import Payment from "../pages/payment.jsx";
import ResetPassword from "../pages/resetPassword/resetPassword.jsx";
import AdminLogin from "../pages/admin/login.jsx";
import MFACheck from "../pages/admin/mfaCheck.jsx";
import AdminMobileResetPassword from "../pages/admin/mobileResetPassword.jsx";
import BusApprovedList from "../pages/businessProfile/approvedList.jsx";
import BusPendingList from "../pages/businessProfile/pendingList.jsx";
import BusRejectedList from "../pages/businessProfile/rejectedList.jsx";
import BuyProdAppList from "../pages/buyProducts/appList.jsx";
import BuyProdPenList from "../pages/buyProducts/penList.jsx";
import BuyProdRejList from "../pages/buyProducts/rejList.jsx";
import BuyProdExpList from "../pages/buyProducts/expList.jsx";
import BuyProdCompList from "../pages/buyProducts/compList.jsx";
import AddCategory from "../pages/category/addCategory.jsx";
import Category from "../pages/category/category.jsx";
import EditCategory from "../pages/category/editCategory.jsx";
import Coins from "../pages/coins/coins.jsx";
import Keywords from "../pages/keywords/keywords.jsx";
import OrgCustomer from "../pages/organisation/customer.jsx";
import OrgDashBoard from "../pages/organisation/dashBoard.jsx";
import OrgEditProfile from "../pages/organisation/editProfile.jsx";
import OrgEmployees from "../pages/organisation/employees.jsx";
import OrgGallery from "../pages/organisation/gallery.jsx";
import OrgLeads from "../pages/organisation/leads.jsx";
import OrgCustomerSupport from '../pages/organisation/customerSupport.jsx'
import OrgMapComponents from "../pages/organisation/mapComponents.jsx";
import OrgMarketPlace from "../pages/organisation/marketPlace.jsx";
import OrgHarvest from '../pages/organisation/harvestPlanner.jsx'
import OrgMembers from "../pages/organisation/members.jsx";
import OrgManageOrganisation from "../pages/organisation/manageOrganisation.jsx";
import OrgSubs from "../pages/organisation/subscription.jsx";
import OrgInVoice from '../pages/organisation/invoiceDetails.jsx'
import OrgSurvey from "../pages/organisation/survey.jsx";
import OrgViewProfile from "../pages/organisation/viewProfile.jsx";
import OrgMfaSettings from "../pages/organisation/mfaSettings.jsx";
import OrgProducts from '../pages/organisation/products.jsx'
import ChatUsers from '../pages/organisation/chat.jsx'
import ProdAddProd from "../pages/product/addProducts.jsx";
import ProdEditProd from "../pages/product/editProduct.jsx";
import ProdProd from "../pages/product/product.jsx";
import ProdViewProd from "../pages/product/viewProduct.jsx";
import ProfileAddCustomer from "../pages/profiles/addCustomer.jsx";
import ProfileCustomer from "../pages/profiles/customer.jsx";
import ProfileEditProfile from "../pages/profiles/editProfile.jsx";
import ProfileMember from "../pages/profiles/members.jsx";
import ProfileOrg from "../pages/profiles/organisation.jsx";
import SellProdAppList from "../pages/sellProducts/approvedList.jsx";
import SellProdExpList from "../pages/sellProducts/expiredList.jsx";
import SellProdPenList from "../pages/sellProducts/pendingList.jsx";
import SellProdRejList from "../pages/sellProducts/rejectedList.jsx";
import SellProdSoldList from "../pages/sellProducts/soldList.jsx";
import SettingsBtypes from "../pages/settings/bTypes.jsx";
import SettingsFaq from "../pages/settings/faq.jsx";
import SettingsIntCrop from "../pages/settings/intrestedCrops.jsx";
import SettingsLang from "../pages/settings/language.jsx";
import SettingsUserType from "../pages/settings/userType.jsx";
import SettingsVariety from "../pages/settings/variety.jsx";
import SettingsVoucher from "../pages/settings/voucher.jsx";
import SubListSub from "../pages/subscription/list.jsx";
import SubManageSub from "../pages/subscription/manage.jsx";
import MFASettings from "../pages/profiles/mfaSettings.jsx";
import Users from "../pages/configurations/users.jsx";
import Roles from "../pages/configurations/roles.jsx";
import HarvestPlanner from '../pages/harvestPlanner.jsx'
import RolePermissions from "../pages/configurations/rolePermissions.jsx";
import MobilePermissions from "../pages/configurations/moblieSubscriptionPermissions.jsx"

export const AppRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AdminLogin />,
  },
  {
    path: "/mfacheck",
    element: <MFACheck />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/profiles/mfasettings",
    element: <MFASettings />,
  },
  {
    path: "/organisation/mfasettings",
    element: <OrgMfaSettings />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/view_product_details",
    element: <ViewProductDetails />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
  {
    path: "/termsAndCondition",
    element: <TermsAndCondition />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/callSupport",
    element: <CallSupport />,
  },
  {
    path:"/harvestPlanner",
    element:<HarvestPlanner/>
  },
  {
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/payment",
    element: <Payment/>,
  },
  {
    path: "/survey",
    element: <Survey />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/mobileResetPassword",
    element: <AdminMobileResetPassword />,
  },
  {
    path: "/BusinessProfile/approvedlist",
    element: <BusApprovedList />,
  },
  {
    path: "/BusinessProfile/pendinglist",
    element: <BusPendingList />,
  },
  {
    path: "/BusinessProfile/rejectedlist",
    element: <BusRejectedList />,
  },
  {
    path: "/buyProducts/applist",
    element: <BuyProdAppList />,
  },
  {
    path: "/buyProducts/rejlist",
    element: <BuyProdRejList />,
  },
  {
    path: "/buyProducts/penlist",
    element: <BuyProdPenList />,
  },
  {
    path: "/buyProducts/explist",
    element: <BuyProdExpList />,
  },
  {
    path: "/buyProducts/complist",
    element: <BuyProdCompList />,
  },
  {
    path: "/category/add_category",
    element: <AddCategory />,
  },
  {
    path: "/category/category",
    element: <Category />,
  },
  {
    path: "/category/edit_category",
    element: <EditCategory />,
  },

  {
    path: "/coins/coins",
    element: <Coins />,
  },

  {
    path: "/keywords/keywords",
    element: <Keywords />,
  },
  {
    path: "/organisation/customer",
    element: <OrgCustomer />,
  },
  {
    path: "/organisation/dashboard",
    element: <OrgDashBoard />,
  },
  {
    path: "/organisation/editprofile",
    element: <OrgEditProfile />,
  },
  {
    path: "/organisation/employees",
    element: <OrgEmployees />,
  },
  {
    path: "/organisation/gallery",
    element: <OrgGallery />,
  },
  {
    path: "/organisation/leads",
    element: <OrgLeads />,
  },
  {
    path: "/organisation/mapcomponents",
    element: <OrgMapComponents />,
  },
  {
    path: "/organisation/marketplace",
    element: <OrgMarketPlace />,
  },
  {
    path:"/organisation/products",
    element:<OrgProducts/>

  },
  {
    path:"/organisation/chat",
    element:<ChatUsers/>
  },
  {
    path:"/organisation/customerSupport",
    element:<OrgCustomerSupport/>
  },
  {
    path:"/organisation/harvestPlanner",
    element:<OrgHarvest/>
  },

  {
    path: "/organisation/members",
    element: <OrgMembers />,
  },
  {
    path: "/organisation/manageOrganisation",
    element: <OrgManageOrganisation />,
  },
  {
    path: "/organisation/subscription",
    element: <OrgSubs />,
  },
  {
    path:"/organisation/invoice",
    element:<OrgInVoice/>
  },
  {
    path: "/organisation/survey",
    element: <OrgSurvey />,
  },
  {
    path: "/organisation/viewprofile",
    element: <OrgViewProfile />,
  },
  {
    path: "/product/addproducts",
    element: <ProdAddProd />,
  },
  {
    path: "/product/editproduct",
    element: <ProdEditProd />,
  },
  {
    path: "/product/product",
    element: <ProdProd />,
  },
  {
    path: "/product/viewproduct",
    element: <ProdViewProd />,
  },
  {
    path: "/profiles/addcustomer",
    element: <ProfileAddCustomer />,
  },
  {
    path: "/profiles/customer",
    element: <ProfileCustomer />,
  },
  {
    path: "/profiles/editprofile",
    element: <ProfileEditProfile />,
  },
  {
    path: "/profiles/members",
    element: <ProfileMember />,
  },
  {
    path: "/profiles/organisation",
    element: <ProfileOrg />,
  },

  {
    path: "/keywords/keywords",
    element: <Keywords />,
  },
  {
    path: "/product/addproducts",
    element: <ProdAddProd />,
  },
  {
    path: "/product/editproduct",
    element: <ProdEditProd />,
  },
  {
    path: "/product/product",
    element: <ProdProd />,
  },
  {
    path: "/profiles/addcustomer",
    element: <ProfileAddCustomer />,
  },
  {
    path: "/profiles/customer",
    element: <ProfileCustomer />,
  },
  {
    path: "/profiles/editprofile",
    element: <ProfileEditProfile />,
  },
  {
    path: "/profiles/members",
    element: <ProfileMember />,
  },
  {
    path: "/profiles/organisation",
    element: <ProfileOrg />,
  },

  {
    path: "sellProducts/approvedlist",
    element: <SellProdAppList />,
  },
  {
    path: "sellProducts/expiredlist",
    element: <SellProdExpList />,
  },
  {
    path: "/sellProducts/pendingList",
    element: <SellProdPenList />,
  },
  {
    path: "/sellProducts/rejectedList",
    element: <SellProdRejList />,
  },
  {
    path: "/sellProducts/soldlist",
    element: <SellProdSoldList />,
  },
  {
    path: "/settings/btypes",
    element: <SettingsBtypes />,
  },
  {
    path: "/settings/faq",
    element: <SettingsFaq />,
  },
  {
    path: "/settings/intrestedCrops",
    element: <SettingsIntCrop />,
  },
  {
    path: "/settings/language",
    element: <SettingsLang />,
  },
  {
    path: "/settings/userType",
    element: <SettingsUserType />,
  },
  
  {
    path: "/settings/variety",
    element: <SettingsVariety />,
  },

  {
    path: "/settings/vocher",
    element: <SettingsVoucher />,
  },
  {
    path: "/subscription/list",
    element: <SubListSub />,
  },
  {
    path: "/subscription/manage",
    element: <SubManageSub />,
  },
  {
    path: "/rolepermissions",
    element: <RolePermissions />,
  },
  {
    path: "/configurations/users",
    element: <Users />,
  },
  {
    path: "/configurations/roles",
    element: <Roles />,
  },
  {
    path: "/configurations/rolepermissions",
    element: <RolePermissions />,
  },
  {
    path: "/configurations/mobilepermissions",
    element: <MobilePermissions />,
  },
]);