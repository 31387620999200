import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { IoSearch } from "react-icons/io5";
import Cookies from "universal-cookie";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import {
  ORG_CUSTOMER,
  ORG_SEARCH_CUSTOMER,
} from "../../constants/url";
import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
const Customer = () => {

  
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [values, setValues] = useState({
    data: [],
    success: false,
    removed: false,
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    keywords: "",
    title: "",
    description: "",
    qty: "",
    price: "",
    unit: "",
    location: "",
    latitude: "",
    longitude: "",
    expdate: "",
    availableDate: "",
    auction_status: "",
    organic_status: "",
    safe_status: "",
    transpot_status: "",
    chargesAfter: "",
    freeDelivery: "",
    startDate: null,
    endDate: null,
  });
  const { startDate, endDate, data } = values;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const requestUrl = `${ORG_CUSTOMER}?_id=${cookies.get(
      "org_id"
    )}&org_name=${cookies.get(
      "org_name"
    )}&startDate=${startDate}&endDate=${endDate}`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          const k = 1;
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].sno = k + +i;
          }
          setValues({ ...values, data: res.data });
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      )
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  const loadData1 = (type, value) => {
    if (type === "start") {
      const requestUrl = `${ORG_CUSTOMER}?_id=${cookies.get(
        "org_id"
      )}&org_name=${cookies.get(
        "org_name"
      )}&startDate=${value}&endDate=${endDate}`;
      privateApiCall(requestUrl, "GET")
        .then(
          (res) => {
            const k = 1;
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].sno = k + +i;
            }
            setValues({ ...values, data: res.data, startDate: value });
          },
          (err) => {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        )
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    } else {
      const requestUrl = `${ORG_CUSTOMER}?_id=${cookies.get(
        "org_id"
      )}&org_name=${cookies.get(
        "org_name"
      )}&startDate=${startDate}&endDate=${value}`;
      privateApiCall(requestUrl, "GET")
        .then(
          (res) => {
            const k = 1;
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].sno = k + +i;
            }
            setValues({ ...values, data: res.data, endDate: value });
          },
          (err) => {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        )
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    }
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value.trim();

    try {
      const requestUrl = `${ORG_SEARCH_CUSTOMER}?_id=${cookies.get(
        "org_id"
      )}&org_name=${cookies.get(
        "org_name"
      )}&query=${searchValue}&startDate=null&endDate=null`;
      const response = await privateApiCall(requestUrl, "GET");

      if (Array.isArray(response.data)) {
        const customersWithSno = response.data.map((customer, index) => {
          return {
            ...customer,
            sno: index + 1,
          };
        });

        setValues({ ...values, data: customersWithSno });
      } else {
        setValues({ ...values, data: [] });
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

 
  const handleChangeStart = (date) => {

 
    
   
    loadData1("start", date ? dayjs(date).format("MM/DD/YYYY") : "",);
 
   
};
const handleChangeEnd = (date) =>  {

 
    
   
  loadData1("end", date ? dayjs(date).format("MM/DD/YYYY") : "",);

 
};

  const dataTable = () => {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#0baae5" />
        <Sidebar type="profiles" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          {" "}
                          <Link to="/organisation/dashboard">
                            {STRING_CONSTANTS.DASHBOARD}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {STRING_CONSTANTS.CUSTOMER}
                        </li>
                      </ol>
                    </div>
                    <h4 className="page-title">{STRING_CONSTANTS.CUSTOMER}</h4>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="col-md-12">
                  <div
                    className="row container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >

{(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProfileCustomers" && item.isDateSearch
          )) && (
          <>
           
                    <div className="col-md-3 ">
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label=" Start Date"
                          format="DD/MM/YYY"
                          // defaultValue={  dayjs(formData.endDate)}
                          defaultValue={dayjs(startDate)}
                          name="startDate"
                          onChange={handleChangeStart}
                          required
                          // minDate={dayjs()}
                          slotProps={{
                            textField: {
                              error:
                                !!startDate && !dayjs(startDate).isValid(),
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-3 ">
                
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="End Date"
                          format="DD/MM/YYYY"
                          defaultValue={dayjs(endDate)}
                          name="endDate"
                          onChange={handleChangeEnd}
                          required
                          // You can control the error state with this condition
                          slotProps={{
                            textField: {
                              error: !!endDate && !dayjs(endDate).isValid(),
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                    
          </>
        )}

                   
{(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProfileCustomers" && item.isSearch
          )) && (
          <>
           
           <div style={{ position: "relative", marginLeft: "auto" }}>
                      <input
                        type="text"
                        placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <IoSearch
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>         
          </>
        )}
                    
                  </div>
                </div>

                <div style={{ width: "98%", marginTop: "20px" }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">{STRING_CONSTANTS.SN_NO}</TableCell>
                          <TableCell align="center">{STRING_CONSTANTS.NAME}</TableCell>
                          <TableCell align="center">{STRING_CONSTANTS.EMAIL}</TableCell>
                          <TableCell align="center">{STRING_CONSTANTS.MOBILE}</TableCell>
                          <TableCell align="center">{STRING_CONSTANTS.SUBSCRIPTION}</TableCell>
                          <TableCell align="center">{STRING_CONSTANTS.VALIDITY}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{row.sno}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">
                              {row.mobile_no}
                            </TableCell>
                            <TableCell align="center">{row.sub}</TableCell>
                            <TableCell align="center">{row.val}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <React.Fragment>{dataTable()}</React.Fragment>;
};

export default Customer;
