import fetch from "isomorphic-fetch";
import { API as url1 } from "../config";
import {
  CREATE_CALL_SUPPORT,
  EDIT_CALL_SUPPORT,
  GET_CALL_SUPPORT,
  UPDATE_CALL_SUPPORT,
  
} from "../constants/url";
import { ERR_POSTING, ERR_GETTING, ERR_ALERT_TITLE, } from "../constants/alertMessage";

import Cookies from "universal-cookie";
import Swal from "sweetalert2/dist/sweetalert2";
import { privateApiCall } from "../api/privateApi";
const cookies = new Cookies();

export const getCallSupportTeam = () => {
  const requestUrl = `${GET_CALL_SUPPORT}`
  return privateApiCall(requestUrl,'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const searchCallSupportTeam = (searchQuery) => {

  const queryParams = new URLSearchParams({ query: searchQuery }).toString();
  const requestUrl = `/app/searchCallSupportTeam?${queryParams}&`
  return privateApiCall(requestUrl,'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: 'OK'  
      });
    });
};


export const createCallSupportTeam = (body) => {
  const requestUrl = `${CREATE_CALL_SUPPORT}`
  return privateApiCall(requestUrl,"POST",body)
 
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const editCallSupportTeam = (id, body) => {
  
  const requestUrl = `${EDIT_CALL_SUPPORT}?id=${id}`
  return privateApiCall(requestUrl,"POST",body)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_POSTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const updateCallSupportTeamStatus = (id, status) => {
  
  const requestUrl = `${UPDATE_CALL_SUPPORT}?id=${id}&status=${status}`
  return privateApiCall(requestUrl,'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};
