import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import {
  ADD_BUS,
  POST_PDT,
  REQ_PDT,
  ADMIN_ADD_USER_URL,
} from "../../constants/url";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Grid,
  TextField,
  Box,
  Fade,
  Button,
} from "@mui/material";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { API as url1 } from "../../config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { getBTypes } from "../../actions/businessProfileAction";
import { Tooltip } from "react-tooltip";
import {
  CUSTOMER_PATH,
  ENABLE_OFFER_PATH,
  ADMIN_SELL_PRODUCT_ADD_PATH,
  ADMIN_ADD_BUSINESS_PROFILE_PATH,
  ADMIN_REQUEST_PRODUCT_ADD_PATH,
  BLOCK_USER_PATH,
  UNBLOCK_USER_PATH,
  SEARCH_CUSTOMER,
} from "../../constants/url";
import {
  ERR_GETTING,
  ERR_POSTING,
  ERROR,
  ERR,
  CONFIRM,
  SUCC_ALERT_TITLE,
  SUCC_ADD_CUS,
  ERR_ALERT_TITLE,
  CONFIRM_BLOCK_CUS,
  CONFIRM_TITLE,
  CONFIRM_UNBLOCK_CUS,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  ALERT_ICON_SUCC,
  ALERT_ICON_QUESTON,
  ALERT_BUTTON_YES,
  ALERT_ICON_WARNING,
  ALERT_BUTTON_NO,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
const Customer = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [showModal, setShowModal] = useState(false);
  const [customer, setCustomer] = useState({
    namee: "",
    email: "",
    mobile: "",
    error: "",
    header: "Add Customer",
    succes: false,
    crops: [],
    message: "",
    imageName: "",
    type_id: "",
    type: "add",
    image: "",
    remove: false,
  });

  const { namee, mobile, email } = customer;

  const [values, setValues] = useState({
    data: [],
    success: false,
    removed: false,
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    keywords: "",
    title: "",
    description: "",
    qty: "",
    price: "",
    unit: "Kg",
    location: "",
    latitude: "",
    longitude: "",
    expdate: "",
    availableDate: "",
    auction_status: 0,
    organic_status: 0,
    safe_status: 0,
    transpot_status: 0,
    chargesAfter: "",
    freeDelivery: "",
    startDate: "",
    endDate: "",
  });

  const [profile, setProfile] = useState({
    bimg1: "",
    bimg2: "",
    bimg3: "",
    bimg4: "",
    selectedBtype: "",
    bType: [],
    cName: "",
    bDescription: "",
    contactPerson: "",
    contactNumber: "",
    address: "",
    serviceCharge: "",
    bLatitude: "",
    bLongitude: "",
    bDeliveryAvailable: "",
    bChargesAfter: "",
    bFreeDelivery: "",
  });

  const {
    serviceCharge,
    bimg1,
    bimg2,
    bimg3,
    bimg4,
    selectedBtype,
    bType,
    cName,
    bDescription,
    contactPerson,
    contactNumber,
    address,

    bDeliveryAvailable,
    bChargesAfter,
    bFreeDelivery,
  } = profile;

  const [request, setRequest] = useState({
    rimg1: "",
    rstartDate: "",
    rendDate: "",
    rlocation: "",
    rLatitude: "",
    rLongitude: "",
    runit: "Kg",
    rprice: "",
    rqty: "",
    rtitle: "",
    rkeywords: "",
    rdescription: "",
  });

  const {
    rimg1,
    rstartDate,
    rendDate,
    rlocation,
    runit,
    rprice,
    rqty,
    rtitle,
    rkeywords,
    rdescription,
  } = request;

  const {
    startDate,
    endDate,
    data,
    img1,
    img2,
    img3,
    img4,
    keywords,
    title,
    description,
    qty,
    price,
    unit,
    location,
    expdate,
    availableDate,
    auction_status,
    organic_status,
    transpot_status,
    safe_status,
    freeDelivery,
    chargesAfter,
  } = values;

  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");

  const [bimage1, setbImage1] = useState("");
  const [bimage2, setbImage2] = useState("");
  const [bimage3, setbImage3] = useState("");
  const [bimage4, setbImage4] = useState("");

  const [rimage, setrImage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleCustomerChange = (name) => (e) => {
    setCustomer({ ...customer, error: false, [name]: e.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const requestUrl = `${ADMIN_ADD_USER_URL}?mobile_no=${mobile}&refCode=&name=${namee}&email=${email}&country_code=`;
    privateApiCall(requestUrl, "GET").then(
      (res) => {
        if (res.data.status) {
          handleCloseModal();
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_ADD_CUS}`,
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then(() => {
            loadData();
          });
        } else {
          Swal.fire({
            title: res.data.message,
            text: "",
            icon: `${ALERT_ICON_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      },
      (err) => {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    );
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowPageChange = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const agritech_token = cookies.get("agritech_token");
  useEffect(() => {
    loadData();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setCustomer({
      ...customer,
      namee: "",
      email: "",
      mobile: "",
    });
    setShowModal(false);
  };
  // const handleStartDateChange = (date) => {

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     startDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
  //   }));
  // };
  const loadData = () => {
    const requestUrl = `${CUSTOMER_PATH}?token=${cookies.get(
      "agritech_token"
    )}&startDate=${startDate}&endDate=${endDate}`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          const k = 1;
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].sno = k + +i;
          }
          setValues({ ...values, data: res.data });
        },
        (err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      )
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });

    getBTypes(agritech_token).then((data) => {
      if (data.error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        setProfile({ ...profile, bType: data.types });
      }
    });
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SEARCH_CUSTOMER}?&query=${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.data && response.data.length) {
        setValues((prevState) => ({
          ...prevState,
          data: response.data,
        }));
        setPage(0);
      } else {
        setValues((prevState) => ({
          ...prevState,
          data: [],
        }));
      }
    } catch (error) {
      console.log(error, "errors");
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const loadData1 = async (type, value) => {
    const requestUrl = `${CUSTOMER_PATH}?token=${cookies.get(
      "agritech_token"
    )}&startDate=${type === "start" ? value : startDate}&endDate=${
      type === "end" ? value : endDate
    }`;

    try {
      const response = await privateApiCall(requestUrl, "GET");

      const data = response.data.map((item, index) => ({
        ...item,
        sno: index + 1,
      }));

      if (type === "start") {
        setValues({ ...values, data, startDate: value });
      } else {
        setValues({ ...values, data, endDate: value });
      }
    } catch (err) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const onFileChange1 = (e) => {
    setValues({
      ...values,
      error: false,
      img1: URL.createObjectURL(e.target.files[0]),
    });
    setImage1(e.target.files[0]);
  };

  const onFileChange2 = (e) => {
    setValues({
      ...values,
      error: false,
      img2: URL.createObjectURL(e.target.files[0]),
    });
    setImage2(e.target.files[0]);
  };

  const onFileChange3 = (e) => {
    setValues({
      ...values,
      error: false,
      img3: URL.createObjectURL(e.target.files[0]),
    });
    setImage3(e.target.files[0]);
  };

  const onFileChange4 = (e) => {
    setValues({
      ...values,
      error: false,
      img4: URL.createObjectURL(e.target.files[0]),
    });
    setImage4(e.target.files[0]);
  };

  const requestFileChange = (e) => {
    setRequest({
      ...request,
      rimg1: URL.createObjectURL(e.target.files[0]),
    });
    setrImage(e.target.files[0]);
  };

  const bChange = (name) => (e) => {
    if (name === "b1") {
      setProfile({
        ...profile,
        bimg1: URL.createObjectURL(e.target.files[0]),
      });
      setbImage1(e.target.files[0]);
    } else if (name === "b2") {
      setProfile({
        ...profile,
        bimg2: URL.createObjectURL(e.target.files[0]),
      });
      setbImage2(e.target.files[0]);
    } else if (name === "b3") {
      setProfile({
        ...profile,
        bimg3: URL.createObjectURL(e.target.files[0]),
      });
      setbImage3(e.target.files[0]);
    } else {
      setProfile({
        ...profile,
        bimg4: URL.createObjectURL(e.target.files[0]),
      });
      setbImage4(e.target.files[0]);
    }
  };

  const handleChangeStart = (date) => {
    loadData1("start", date ? dayjs(date).format("MM/DD/YYYY") : "");
  };

  const handleChangeEnd = (date) => {
    loadData1("end", date ? dayjs(date).format("MM/DD/YYYY") : "");
  };


  const create = (row) => {
    const handleChange = (name) => (e) => {
      setValues({ ...values, error: false, [name]: e.target.value });
    };
    const handleCheckBoxChange = (name, type) => (e) => {
      setValues({ ...values, [name]: type });
    };

    const createPost = (e) => {
      e.persist();
      const formData = new FormData();

      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("image4", image4);
      formData.append("mobile_no", row.mobile_no);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("keywords", keywords);
      formData.append("qty", qty);
      formData.append("price", price);
      formData.append("location", location);
      formData.append("unit", unit);
      formData.append("latitude", row.latitude);
      formData.append("longitude", row.longitude);
      formData.append("expDate", expdate);
      formData.append("availableDate", availableDate);
      formData.append("auction_status", auction_status);
      formData.append("organic_status", organic_status);
      formData.append("safe_status", safe_status);
      formData.append("transpot_status", transpot_status);
      formData.append("chargesAfter", chargesAfter);
      formData.append("freeDelivery", freeDelivery);
      const requestUrl = `${ADMIN_SELL_PRODUCT_ADD_PATH}`;
      privateApiCall(requestUrl, "POST", formData).then((res) => {
        if (!res.data.status) {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then((result) => {
            if (result.isConfirmed) {
              setValues({
                ...values,
                img1: "",
                img2: "",
                img3: "",
                img4: "",
                keywords: "",
                title: "",
                description: "",
                qty: "",
                price: "",
                unit: "Kg",
                location: "",
                latitude: "",
                longitude: "",
                expdate: "",
                availableDate: "",
                auction_status: 0,
                organic_status: 0,
                safe_status: 0,
                transpot_status: 0,
                chargesAfter: "",
                freeDelivery: "",
              });
              setImage1("");
              setImage2("");
              setImage3("");
              setImage4("");
            }
          });
        }
      });
    };

    const handleBChange = (name) => (e) => {
      setProfile({ ...profile, error: false, [name]: e.target.value });
    };
    const handleBCheckBoxChange = (name, type) => (e) => {
      setProfile({ ...profile, [name]: type });
    };
    const createBusiness = (e) => {
      const formData = new FormData();
      formData.append("image1", bimage1);
      formData.append("image2", bimage2);
      formData.append("image3", bimage3);
      formData.append("image4", bimage4);
      formData.append("mobile_no", row.mobile_no);
      formData.append("btype", selectedBtype);
      formData.append("company_name", cName);
      formData.append("description", bDescription);
      formData.append("contact_person", contactPerson);
      formData.append("contact_number", contactNumber);
      formData.append("address", address);
      formData.append("latitude", row.latitude);
      formData.append("longitude", row.longitude);
      formData.append("expDate", expdate);
      formData.append("charges", serviceCharge);
      formData.append("deliveryAvailable", bDeliveryAvailable);
      formData.append("chargesAfter", bChargesAfter);
      formData.append("freeDelivery", bFreeDelivery);

      const requestUrl = `${ADMIN_ADD_BUSINESS_PROFILE_PATH}`;
      privateApiCall(requestUrl, "POST", formData).then((res) => {
        if (!res.data.status) {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setProfile({
                ...profile,
                bimg1: "",
                bimg2: "",
                bimg3: "",
                bimg4: "",
                selectedBtype: "",
                cName: "",
                bDescription: "",
                contactPerson: "",
                contactNumber: "",
                address: "",
                serviceCharge: "",
                bLatitude: "",
                bLongitude: "",
                bDeliveryAvailable: "",
                bChargesAfter: "",
                bFreeDelivery: "",
              });
              setbImage1("");
              setbImage2("");
              setbImage3("");
              setbImage4("");
            }
          });
        }
      });
    };

    const handleRequestChange = (name) => (e) => {
      setRequest({ ...request, error: false, [name]: e.target.value });
    };

    const requestPost = (e) => {
      e.persist();
      const formData = new FormData();
      formData.append("image1", rimage);
      formData.append("mobile_no", row.mobile_no);
      formData.append("title", rtitle);
      formData.append("description", rdescription);
      formData.append("keywords", rkeywords);
      formData.append("qty", rqty);
      formData.append("price", rprice);
      formData.append("location", rlocation);
      formData.append("unit", runit);
      formData.append("latitude", row.latitude);
      formData.append("longitude", row.longitude);
      formData.append("startDate", rstartDate);
      formData.append("endDate", rendDate);
      const requestUrl = `${ADMIN_REQUEST_PRODUCT_ADD_PATH}`;
      privateApiCall(requestUrl, "POST", formData).then((res) => {
        if (!res.data.status) {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setRequest({
                ...request,
                rimg1: "",
                rkeywords: "",
                rtitle: "",
                rdescription: "",
                rqty: "",
                rprice: "",
                runit: "Kg",
                rlocation: "",
                rlatitude: "",
                rlongitude: "",
                rstartDate: "",
                rendDate: "",
              });
              setrImage("");
            }
          });
        }
      });
    };

    return (
      <span>
        <div style={{ display: "flex", marginTop: "8px" }}>
          <div style={{ marginLeft: "-8px" }}>
            {permissions.some(
              (item) =>
                item.keyword === "profilesCustomers" &&
                item.isPostProduct === true
            ) && (
              <>
                <img
                  className="mr-2"
                  data-toggle="modal"
                  // data-target={"#post" + row._id}
                  data-target={row ? `#post${row._id}` : ""}
                  data-tooltip-id="1"
                  data-tooltip-content={`${POST_PDT}`}
                  src="/assets/icons/post.png"
                  width="20"
                  height="20"
                  alt=""
                ></img>
                <Tooltip id="1" />
              </>
            )}
          </div>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" &&
              item.isAddBusiness === true
          ) && (
            <div>
              <img
                className="mr-2"
                data-toggle="modal"
                data-target={"#business" + row._id}
                src="/assets/icons/business.png"
                data-tooltip-id="2"
                data-tooltip-content={`${ADD_BUS}`}
                width="17"
                height="17"
                alt=""
              ></img>
              <Tooltip id="2" />
            </div>
          )}
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" &&
              item.isRequestProduct === true
          ) && (
            <div>
              <img
                className="mr-2"
                data-toggle="modal"
                data-target={"#request" + row._id}
                src="/assets/icons/request.png"
                data-tooltip-id="3"
                data-tooltip-content={`${REQ_PDT}`}
                width="22"
                height="22"
                alt=""
              ></img>
              <Tooltip id="3" />
            </div>
          )}
        </div>

        {/*  Post Product */}
        <div
          id={"post" + row._id}
          className="modal fade bs-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <form onSubmit={createPost}>
              <a href="#" title="Approve" data-toggle="tooltip">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5 className="modal-title text-dark" id="myModalLabel">
                      {STRING_CONSTANTS.POST_PRODUCT} {row.name}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row ">
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_1}
                        </div>

                        {img1 !== "" ? (
                          <div>
                            <img
                              src={img1}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            onChange={onFileChange1}
                            accept="image/png,  image/jpeg"
                            className="mt-3 margin-top-10"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_2}
                        </div>

                        {img2 !== "" ? (
                          <div>
                            <img
                              src={img2}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={onFileChange2}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_3}
                        </div>

                        {img3 !== "" ? (
                          <div>
                            <img
                              src={img3}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={onFileChange3}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_4}
                        </div>

                        {img4 !== "" ? (
                          <div>
                            <img
                              src={img4}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={onFileChange4}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.TITLE}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={title}
                            placeholder={STRING_CONSTANTS.TITLE_PLACEHOLDER}
                            onChange={handleChange("title")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.KEYWORDS_SEPERATED}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={keywords}
                            placeholder={STRING_CONSTANTS.KEYWORD_PLACEHOLDER}
                            onChange={handleChange("keywords")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.UNIT}
                          </div>

                          <select
                            id="single"
                            className="form-control"
                            value={unit}
                            onChange={handleChange("unit")}
                          >
                            {[`${STRING_CONSTANTS.UNIT_ARRAY}`].map(
                              (person, i) => (
                                <option key={i} value={person}>
                                  {" "}
                                  {person}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.QUANTITY}
                          </div>

                          <input
                            type="number"
                            className="form-control"
                            value={qty}
                            placeholder="Enter Quantity"
                            onChange={handleChange("qty")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.PRICE}
                          </div>

                          <input
                            type="number"
                            className="form-control"
                            value={price}
                            placeholder={STRING_CONSTANTS.PRICE_PLCEHOLDER}
                            onChange={handleChange("price")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.AVAIL_DATE}
                          </div>

                          <input
                            type="date"
                            className="form-control"
                            value={availableDate}
                            placeholder={STRING_CONSTANTS.AVIL_DATE_PLACEHOLDER}
                            onChange={handleChange("availableDate")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.EXPIRY_DATE}
                          </div>

                          <input
                            type="date"
                            className="form-control"
                            value={expdate}
                            placeholder={STRING_CONSTANTS.EXP_DATE_PLACEHOLDER}
                            onChange={handleChange("expdate")}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.LOCATION}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={location}
                            placeholder={STRING_CONSTANTS.LOCATION_PLACEHOLDER}
                            onChange={handleChange("location")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.DESCRIPTION}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={description}
                            placeholder={
                              STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                            }
                            onChange={handleChange("description")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-3 ml-3 mt-2 d-flex justify-content-start ">
                        <div className="form-group">
                          {auction_status === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleCheckBoxChange(
                                  "auction_status",
                                  0
                                )}
                                size="20"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleCheckBoxChange(
                                  "auction_status",
                                  1
                                )}
                                size="20"
                              />
                            </i>
                          )}

                          <label className="ml-2 text-dark">
                            {STRING_CONSTANTS.FOR_AUCTION}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 ml-3 mt-2 d-flex justify-content-start ">
                        <div className="form-group">
                          {organic_status === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleCheckBoxChange(
                                  "organic_status",
                                  0
                                )}
                                size="25"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleCheckBoxChange(
                                  "organic_status",
                                  1
                                )}
                                size="25"
                              />
                            </i>
                          )}

                          <label className="ml-2 text-dark">
                            {STRING_CONSTANTS.ORGANIC}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 ml-3 mt-2 d-flex justify-content-start ">
                        <div className="form-group">
                          {safe_status === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleCheckBoxChange("safe_status", 0)}
                                size="25"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleCheckBoxChange("safe_status", 1)}
                                size="25"
                              />
                            </i>
                          )}

                          <label className="ml-2 text-dark">
                            {STRING_CONSTANTS.SAFE_TO_EAT}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 ml-3 mt-2 d-flex justify-content-start ">
                        <div className="form-group">
                          {transpot_status === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleCheckBoxChange(
                                  "transpot_status",
                                  0
                                )}
                                size="25"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleCheckBoxChange(
                                  "transpot_status",
                                  1
                                )}
                                size="25"
                              />
                            </i>
                          )}

                          <label className="ml-2 text-dark">
                            {STRING_CONSTANTS.TRANSPORT_AVAILABLE}
                          </label>
                        </div>
                      </div>
                      {transpot_status === 1 && (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.FREE_DELEVERY_UPTO}
                              </div>

                              <input
                                type="text"
                                className="form-control"
                                value={freeDelivery}
                                placeholder={STRING_CONSTANTS.KM_PLACEHOLDER}
                                onChange={handleChange("freeDelivery")}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.CHARGES_AFTER_KM}
                              </div>

                              <input
                                type="text"
                                className="form-control"
                                value={chargesAfter}
                                placeholder={STRING_CONSTANTS.PRICE_PLCEHOLDER}
                                onChange={handleChange("chargesAfter")}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      {STRING_CONSTANTS.CLOSE}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      {STRING_CONSTANTS.SUBMIT}
                    </button>
                  </div>
                </div>
              </a>
            </form>
          </div>
        </div>

        {/*  Request Product */}
        <div
          id={"request" + row._id}
          className="modal fade bs-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <form onSubmit={requestPost}>
              <a href="#" title="Approve" data-toggle="tooltip">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5 className="modal-title text-dark" id="myModalLabel">
                      {STRING_CONSTANTS.REQUEST_PRODUCT}
                      {row.name}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row ">
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_1}
                        </div>

                        {rimg1 !== "" ? (
                          <div>
                            <img
                              src={rimg1}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={requestFileChange}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>

                      <div className="row m-1">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.TITLE}
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              value={rtitle}
                              placeholder={STRING_CONSTANTS.TITLE_PLACEHOLDER}
                              onChange={handleRequestChange("rtitle")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.KEYWORDS_SEPERATED}
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              value={rkeywords}
                              placeholder={STRING_CONSTANTS.KEYWORD_PLACEHOLDER}
                              onChange={handleRequestChange("rkeywords")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.UNIT}
                            </div>

                            <select
                              id="single"
                              className="form-control"
                              value={runit}
                              onChange={handleRequestChange("runit")}
                            >
                              {[`${STRING_CONSTANTS.UNIT_ARRAY}`].map(
                                (person, i) => (
                                  <option key={i} value={person}>
                                    {" "}
                                    {person}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.QUANTITY}
                            </div>

                            <input
                              type="number"
                              className="form-control"
                              value={rqty}
                              placeholder={
                                STRING_CONSTANTS.QUANTITY_PLACEHOLDER
                              }
                              onChange={handleRequestChange("rqty")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.PRICE}
                            </div>

                            <input
                              type="number"
                              className="form-control"
                              value={rprice}
                              placeholder={STRING_CONSTANTS.PRICE_PLCEHOLDER}
                              onChange={handleRequestChange("rprice")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.START_DATE}
                            </div>

                            <input
                              type="date"
                              className="form-control"
                              value={rstartDate}
                              placeholder={
                                STRING_CONSTANTS.AVIL_DATE_PLACEHOLDER
                              }
                              onChange={handleRequestChange("rstartDate")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.END_DATE}
                            </div>

                            <input
                              type="date"
                              className="form-control"
                              value={rendDate}
                              placeholder={
                                STRING_CONSTANTS.EXP_DATE_PLACEHOLDER
                              }
                              onChange={handleRequestChange("rendDate")}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.LOCATION}
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              value={rlocation}
                              placeholder={
                                STRING_CONSTANTS.LOCATION_PLACEHOLDER
                              }
                              onChange={handleRequestChange("rlocation")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div
                              className="text-dark text-left font-size-16 mb-2 "
                              style={{ letterSpacing: "0.04rem" }}
                            >
                              {STRING_CONSTANTS.DESCRIPTION}
                            </div>

                            <input
                              type="text"
                              className="form-control"
                              value={rdescription}
                              placeholder={
                                STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                              }
                              onChange={handleRequestChange("rdescription")}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      {STRING_CONSTANTS.CLOSE}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      {STRING_CONSTANTS.SUBMIT}
                    </button>
                  </div>
                </div>
              </a>
            </form>
          </div>
        </div>

        {/* Add Business Profile */}
        <div
          id={"business" + row._id}
          className="modal fade bs-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <form onSubmit={createBusiness}>
              <a href="#" title="Approve" data-toggle="tooltip">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5 className="modal-title text-dark" id="myModalLabel">
                      {STRING_CONSTANTS.ADD_BUSSINESS_PROFILE_FOR}
                      {row.name}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row ">
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_1}
                        </div>

                        {bimg1 !== "" ? (
                          <div>
                            <img
                              src={bimg1}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b1")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_2}
                        </div>

                        {bimg2 !== "" ? (
                          <div>
                            <img
                              src={bimg2}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b2")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_3}
                        </div>

                        {bimg3 !== "" ? (
                          <div>
                            <img
                              src={bimg3}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b3")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_4}
                        </div>

                        {bimg4 !== "" ? (
                          <div>
                            <img
                              src={bimg4}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b4")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.COMPANY_NAME}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={cName}
                            placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                            onChange={handleBChange("cName")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.CONTACT_PERSON}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={contactPerson}
                            placeholder={
                              STRING_CONSTANTS.CONTACT_PERSON_PLACEHOLDER
                            }
                            onChange={handleBChange("contactPerson")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.CONTACT_NUMBER}
                          </div>

                          <input
                            type="number"
                            className="form-control"
                            value={contactNumber}
                            placeholder={
                              STRING_CONSTANTS.CONTACT_NUMBER_PLACEHOLDER
                            }
                            onChange={handleBChange("contactNumber")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.BUSSINESS_TYPE}
                          </div>

                          <select
                            id="single"
                            className="form-control"
                            value={selectedBtype}
                            onChange={handleBChange("selectedBtype")}
                          >
                            <option value="">
                              {STRING_CONSTANTS.SELECT_BUSINESS_TYPE}
                            </option>

                            {bType.map((person, i) => (
                              <option key={i} value={person._id}>
                                {" "}
                                {person.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.SERVICE_CHARGES}
                          </div>

                          <input
                            type="number"
                            className="form-control"
                            value={serviceCharge}
                            placeholder={STRING_CONSTANTS.CHARGES_PLACEHOLDER}
                            onChange={handleBChange("serviceCharge")}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.ADDRESS}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={address}
                            placeholder={STRING_CONSTANTS.ADDRESS_PLACEHOLDER}
                            onChange={handleBChange("address")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.DESCRIPTION}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={bDescription}
                            placeholder={
                              STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                            }
                            onChange={handleBChange("bDescription")}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-3 ml-3 mt-2 d-flex justify-content-start ">
                        <div className="form-group">
                          {bDeliveryAvailable === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleBCheckBoxChange(
                                  "bDeliveryAvailable",
                                  0
                                )}
                                size="25"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleBCheckBoxChange(
                                  "bDeliveryAvailable",
                                  1
                                )}
                                size="25"
                              />
                            </i>
                          )}

                          <label className="ml-2 text-dark">
                            {STRING_CONSTANTS.DELEVERY_AVAILABLE}
                          </label>
                        </div>
                      </div>
                      {bDeliveryAvailable === 1 && (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.FREE_DELEVERY_UPTO}
                              </div>

                              <input
                                type="number"
                                className="form-control"
                                value={bFreeDelivery}
                                placeholder={STRING_CONSTANTS.KM_PLACEHOLDER}
                                onChange={handleBChange("bFreeDelivery")}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.CHARGES_AFTER_KM}
                              </div>

                              <input
                                type="number"
                                className="form-control"
                                value={bChargesAfter}
                                placeholder={
                                  STRING_CONSTANTS.CHARGES_PLACEHOLDER
                                }
                                onChange={handleBChange("bChargesAfter")}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      {STRING_CONSTANTS.CLOSE}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      {STRING_CONSTANTS.SUBMIT}
                    </button>
                  </div>
                </div>
              </a>
            </form>
          </div>
        </div>
      </span>
    );
  };

  const deleteCus = (row) => {
    const handleBlock = (event, url) => {
      event.preventDefault();
      Swal.fire({
        title: `${CONFIRM_TITLE}`,
        text: `${CONFIRM_BLOCK_CUS}`,
        icon: `${ ALERT_ICON_WARNING }`,
        showCancelButton: true,
        confirmButtonText: `${ ALERT_BUTTON_YES }`,
        cancelButtonText: `${ ALERT_BUTTON_NO }`,
      }).then((result) => {
        if (result.isConfirmed) {
          privateApiCall(url, "POST", {})
            .then((response) => {
              if (response.status === 200) {
                Swal.fire(`${STRING_CONSTANTS.BLOCKED_MESSAGE}`);
                loadData();
              } else {
                Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
              }
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
            });
        }
      });
    };

    const handleUnblock = (event, url) => {
      event.preventDefault();
      Swal.fire({
        title: `${CONFIRM_TITLE}`,
        text: `${CONFIRM_UNBLOCK_CUS}`,
        icon: `${ ALERT_ICON_ERR }`,
        showCancelButton: true,
        confirmButtonText:` ${ ALERT_BUTTON_YES }`,
        cancelButtonText: `${ ALERT_BUTTON_NO }`,
      }).then((result) => {
        if (result.isConfirmed) {
          privateApiCall(url, "POST", {})
            .then((response) => {
              if (response.status === 200) {
                Swal.fire(`${STRING_CONSTANTS.UNBLOCKED_MESSAGE}`);
                loadData();
              } else {
                Swal.fire(`${STRING_CONSTANTS.ERR_UNBLOCK_MESSAGE}`);
              }
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_UNBLOCK_MESSAGE}`);
            });
        }
      });
    };

    if (row && row.block_status === 0) {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isBlock === true
          ) && (
            <>
              <button
                type="button"
                className="btn btn-icon waves-effect  waves-light btn-danger"
                alt="Block"
                data-tooltip-id="8"
                data-tooltip-content={`Block`}
                data-toggle="modal"
                data-target={"#myModal" + row._id}
                onClick={(event) =>
                  handleBlock(
                    event,
                    `${BLOCK_USER_PATH}/${row._id}/?token=${cookies.get(
                      "agritech_token"
                    )}`
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "8px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  marginTop: "10px",
                }}
              >
                {" "}
                <i
                  className="fas fa-thumbs-down"
                  style={{ fontSize: "8px", marginBottom: "0px" }}
                ></i>{" "}
              </button>
              <Tooltip id="8" />
            </>
          )}
        </span>
      );
    } else if (row && row.block_status === 1) {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isUnblock === true
          ) && (
            <>
              <button
                type="button"
                className="btn btn-icon waves-effect   waves-light btn-success"
                data-toggle="modal"
                alt="Unblock"
                data-tooltip-id="7"
                data-tooltip-content={`Unblock`}
                data-target={"#myModal" + row._id}
                onClick={(event) =>
                  handleUnblock(
                    event,
                    `${UNBLOCK_USER_PATH}/${row._id}/?token=${cookies.get(
                      "agritech_token"
                    )}`
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "9px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  marginTop: "10px",
                }}
              >
                {" "}
                <i
                  className="fas fa-thumbs-up"
                  style={{ fontSize: "8px", marginBottom: "0px" }}
                ></i>{" "}
              </button>
              <Tooltip id="7" />
            </>
          )}
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.CUSTOMERS}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.CUSTOMERS}</h4>
                </div>
              </div>
            </div>
            <div className="card-box">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-2 mt-3i">
                    {permissions.some(
                      (item) =>
                        item.keyword === "profilesCustomers" &&
                        item.isCreate === true
                    ) && (
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary mb-3"
                        onClick={handleShowModal}
                      >
                        <i className="fa fa-user"></i>{" "}
                        {STRING_CONSTANTS.ADD_CUSTOMER}
                      </button>
                    )}
                    <Modal
                      open={showModal}
                      onClose={handleCloseModal}
                      closeAfterTransition
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disableBackdropClick
                    >
                      <Fade in={showModal}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            p: 4,
                            borderRadius: 2,
                            width: "60%",
                            marginBottom: "50px",
                          }}
                        >
                          <h2>{STRING_CONSTANTS.ADD_CUSTOMER}</h2>
                          <form onSubmit={onSubmit}>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  variant="outlined"
                                  name="namee"
                                  value={namee}
                                  onChange={handleCustomerChange("namee")}
                                  placeholder={
                                    STRING_CONSTANTS.NAME_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="email"
                                  variant="outlined"
                                  name="email"
                                  value={email}
                                  onChange={handleCustomerChange("email")}
                                  placeholder={
                                    STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="mobileno"
                                  variant="outlined"
                                  name="mobileno"
                                  value={mobile}
                                  onChange={handleCustomerChange("mobile")}
                                  placeholder={
                                    STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModal}
                                style={{ marginRight: "10px" }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </Grid>
                          </form>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                  <div
                    className="row container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                                {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isDateSearch === true
          ) && (
            <>
             <div className="col-md-3 ">
                     
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                       <DemoContainer components={["DatePicker"]}>
                         <DatePicker
                           label=" Start Date"
                           format="DD/MM/YYY"
                           // defaultValue={  dayjs(formData.endDate)}
                           defaultValue={dayjs(startDate)}
                           name="startDate"
                           onChange={handleChangeStart}
                           required
                           // minDate={dayjs()}
                           slotProps={{
                             textField: {
                               error:
                                 !!startDate && !dayjs(startDate).isValid(),
                             },
                           }}
                         />
                       </DemoContainer>
                     </LocalizationProvider>
                   </div>
                   <div className="col-md-3 ">
                   
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                       <DemoContainer components={["DatePicker"]}>
                         <DatePicker
                           label="End Date"
                           format="DD/MM/YYYY"
                           defaultValue={dayjs(endDate)}
                           name="endDate"
                           onChange={handleChangeEnd}
                           required
                           // You can control the error state with this condition
                           slotProps={{
                             textField: {
                               error: !!endDate && !dayjs(endDate).isValid(),
                             },
                           }}
                         />
                       </DemoContainer>
                     </LocalizationProvider>
                   </div>
            </>
          )}
                  
                    <div style={{ position: "relative", marginLeft: "auto" }}>

                    {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isSearch === true
          ) && (
            <>
             <input
                        type="text"
                        placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <i
                        className="fas fa-search"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#aaa",
                        }}
                      ></i>
            </>
          )}
                    
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "98%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {STRING_CONSTANTS.SN_NO}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.NAME}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.EMAIL}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.MOBILE}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.SUBSCRIPTION}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.VALIDITY}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.COINS}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.LANGUAGE}
                        </TableCell>

                        <TableCell align="left">
                          {STRING_CONSTANTS.STATUS}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.ACTIONS}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={row._id}>
                            <TableCell align="left">{index + 1}</TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "150px" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.mobile_no}</TableCell>
                            <TableCell align="left">{row.sub}</TableCell>
                            <TableCell align="left">{row.val}</TableCell>
                            <TableCell align="left">{row.coins}</TableCell>
                            <TableCell align="left">{row.language}</TableCell>

                            <TableCell>
                              {" "}
                              {row.block_status === 0 ? (
                                <span className="btn-sm btn-success ">
                                  {STRING_CONSTANTS.ACTIVE}
                                </span>
                              ) : (
                                <span className="btn-sm btn-danger">
                                  {STRING_CONSTANTS.INACTIVE}
                                </span>
                              )}{" "}
                            </TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {create(row)}
                                {deleteCus(row)}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  component="div"
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowPageChange}
                ></TablePagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
