import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { getAllPayments } from "../actions/adminAction";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../constants/alertMessage";
import { privateApiCall } from "../api/privateApi";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { formatDate } from "../utils/dateUtils";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Payments = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    error: "",
    payments: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    loadPayments();
  }, []);

  useEffect(() => {
    if (searchValue || startDate || endDate) {
      handleSearch();
    } else {
      loadPayments();
    }
  }, [startDate, endDate, searchValue]);

  const handleChangeStart = (date) => {
    setStartDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };

  const loadPayments = () => {
    getAllPayments()
      .then((response) => {
        if (response && response.status !== undefined && response.payments) {
          if (response.status) {
            setValues({ ...values, payments: response.payments });
          } else {
            console.error(response.message);
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        } else {
          console.error(response);
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleSearch = async () => {
    try {
      const requestUrl = `/api/payments/searchPayments?&query=${searchValue}&startDate=${startDate}&endDate=${endDate}`;
      const response = await privateApiCall(requestUrl, "GET");
      if (response.data.payments) {
        setValues({ ...values, payments: response.data.payments });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.PAYMENTS}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.PAYMENTS}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {permissions.some(
                        (item) =>
                          item.keyword === "payment" &&
                          item.isDateSearch === true
                      ) && (
                        <>
                          <div style={{ paddingRight: "16px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="Start Date"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(startDate)}
                                  name="startDate"
                                  onChange={handleChangeStart}
                                  required
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!startDate &&
                                        !dayjs(startDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="End Date"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(endDate)}
                                  name="endDate"
                                  onChange={handleChangeEnd}
                                  required
                                  // You can control the error state with this condition
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!endDate && !dayjs(endDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          position: "relative",
                          marginLeft: "auto",
                        }}
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "payment" && item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                              className="form-control"
                              style={{
                                width: "200px",
                                paddingLeft: "35px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.PAYMENT_ID}</TableCell>
                            <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                            <TableCell>{STRING_CONSTANTS.CONTACT_NO}</TableCell>

                            <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                            <TableCell>{STRING_CONSTANTS.PURPOSE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.payments
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((payment, index) => (
                              <TableRow key={payment._id}>
                                <TableCell>
                                  {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell>{payment.payment_id}</TableCell>
                                <TableCell>{payment.name}</TableCell>
                                <TableCell>{payment.mobile_no}</TableCell>
                                <TableCell>{payment.amount}</TableCell>
                                <TableCell>{payment.purpose}</TableCell>
                                <TableCell>
                                  {formatDate(payment.time)}
                                </TableCell>
                                <TableCell>{payment.payment_status}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={values.payments.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
