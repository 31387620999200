import React, { useState } from "react";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ImageModal from "../../../images/imageModal";
import { privateApiCall } from "../../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { SUCC_FEEDBACK, ENTER_FEEDBACK } from "../../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../../constants/stringConstants";


const Viewproduct = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleSend = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      toast.error(ENTER_FEEDBACK, {
        position: "top-right",
        transition: Bounce,
      });
      return;
    }
    try {
      const requestUrl = `/api/buyproduct/replayToBProduct/?&_id=${product.id}&replay=${message}`;
      const response = await privateApiCall(requestUrl, "GET");
      if (response.data.status) {
        toast.info(SUCC_FEEDBACK, {
          position: "top-right",
          transition: Bounce,
        });
        setMessage("");
      }
    } catch (error) {
      toast.error("Failed to send the message.", {
        position: "top-right",
        transition: Bounce,
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      {product ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: "white",
              borderRadius: "5px",
              marginBottom: "20px",
              border: "1px solid #ddd",
            }}
          >
            <div>
              <h4>{product.customer}</h4>
              <p style={{ color: "gray", margin: "2px 0" }}>{product.title}</p>
              <p style={{ color: "gray", margin: "2px 0" }}>
                {product.location}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <p style={{ color: "gray", margin: "2px 0" }}>
                {product.mobile_no}
              </p>
              <p style={{ color: "gray", margin: "2px 0" }}>{product.email}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            <button
              style={{
                padding: "5px",
                backgroundColor: "#0baae5",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {STRING_CONSTANTS.SET_REMINDER}
            </button>
            <button
              style={{
                padding: "5px",
                backgroundColor: "#0baae5",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {STRING_CONSTANTS.ADD_NOTES}
            </button>
            <button
              style={{
                padding: "5px",
                backgroundColor: "#0baae5",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {STRING_CONSTANTS.APPLY_LABEL}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              backgroundColor: "white",
              borderRadius: "5px",
              border: "1px solid #ddd",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <div>
                <img
                  src={product.image1 || "/assets/images/no_image.png"}
                  alt="product"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsModalOpen(true)}
                />
                <p style={{ color: "gray" }}>Quantity: {product.qty}</p>
              </div>
              <p style={{ color: "gray" }}>{product.startDate}</p>
            </div>
            <p style={{ color: "gray" }}>{product.description}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  border: "1px solid #ddd",
                }}
              >
                {STRING_CONSTANTS.TOTAL_PRICE} {product.price} </button>
            </div>
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={STRING_CONSTANTS.TYPE_YOUR_MESSSAGE}
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ddd",
                width: "100%",
                paddingRight: "40px",
              }}
            />
            <IconButton
              onClick={handleSend}
              color="primary"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <SendIcon />
            </IconButton>
          </div>

          <ImageModal
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            imageSrc={product.image1}
            alt="product"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      ) : (
        <p>{STRING_CONSTANTS.NO_DATA_FOUND}</p>
      )}
    </div>
  );
};

export default Viewproduct;
