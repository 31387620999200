import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, TextField, Grid } from "@mui/material";
import {
  GET_ORG_PROFILE,
  MY_ACCOUNT,
} from "../../constants/url";
import { privateApiCall } from "../../api/privateApi";
import { ALERT_BUTTON_OK, ALERT_ICON_ERR, ERR_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Viewprofile = () => {
  const [activeTab, setActiveTab] = useState("company");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    yearOfEstablishment: "",
    ceoName: "",
    websiteURL: "",
    googleBusinessURL: "",
    facebookBusinessURL: "",
    instagramBusinessURL: "",
    pincode: "",
    state: "",
    Address: "",
    Locality: "",
    city: "",
    country: "",
    area: "",
    landmark: "",
    gstin: "",
    tan: "",
    iec: "",
    pan: "",
    cin: "",
    accountnumber: "",
    holdername: "",
    bankname: "",
    reaccountnumber: "",
    ifsc: "",
    branchname: "",
    logo: "/assets/icons/try.jpg",
  });

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = (event) => {
    event.preventDefault();
    const nextTabMap = {
      1: "2",
      2: "3",
      3: "4",
    };

    const nextTab = nextTabMap[value];
    if (nextTab) {
      setValue(nextTab);
    }
  };

  useEffect(() => {
    if (
      cookies.get("login_type") !== "callSupport"
      // && cookies.get("login_type") !== "admin"
    ) {
      const requestUrl = `${GET_ORG_PROFILE}/${cookies.get(
        "org_id"
      )}?token=${cookies.get("org_token")}`;
      privateApiCall(requestUrl, "GET")
        .then((res) => {
          console.log(res.data.admin, "view profile");
          const adminData = res.data.admin[0];
          setFormData(adminData);
        })
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    }
  }, []);

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.MY_ACCOUNT}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.MY_ACCOUNT}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <form>
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-12">
                        <div className="p-20">
                          <div className="d-flex justify-content-end">
                            <div className="d-flex justify-content-end">
                              <Link to="/organisation/editprofile">
                                <Button
                                  variant="contained"
                                  type="button"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                >
                                  {STRING_CONSTANTS.EDIT}
                                </Button>
                              </Link>
                            </div>
                          </div>

                          {/* ------------------------------------------------------------------------ */}

                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Company Details" value="1" />
                                <Tab label="Address Details" value="2" />
                                <Tab label="Statutory Details" value="3" />
                                <Tab label="Bank Account Details" value="4" />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6}>
                                    <img
                                      src={
                                        formData.logo || "/assets/icons/try.jpg"
                                      }
                                      alt="Company Logo"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        marginBottom: "10px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                    />

                                    <TextField
                                      label="Company Name"
                                      value={formData.name}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                     <TextField
                                      
                                      value={formData.displayName}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Primary Email"
                                      value={formData.email}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      InputProps={{ readOnly: true }}
                                    />
                                  
                                    <TextField
                                      label="Secondary Email"
                                      value={formData.secondaryEmail}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />

                                    <TextField
                                      label="Year of Establishment"
                                      value={formData.yearOfEstablishment}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginTop: "110px" }}
                                  >
                                    <TextField
                                      label="CEO Name"
                                      value={formData.ceoName}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="WebSite URL"
                                      value={formData.websiteURL}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Google Business URL"
                                      value={formData.googleBusinessURL}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Facebook Business URL"
                                      value={formData.facebookBusinessURL}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Instagram Business URL"
                                      value={formData.instagramBusinessURL}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  onClick={handleNext}
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                              </div>
                            </TabPanel>
                            <TabPanel value="2">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Address (Building No/floor)"
                                      value={formData.Address}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Locality"
                                      value={formData.locality}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="City"
                                      value={formData.city}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Country"
                                      value={formData.country}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Address (Area/Street)"
                                      value={formData.area}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Landmark"
                                      value={formData.landmark}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="State"
                                      value={formData.state}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="PIN Code"
                                      value={formData.pincode}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  onClick={handleNext}
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                              </div>
                            </TabPanel>
                            <TabPanel value="3">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="GSTIN"
                                      value={formData.gstin}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="TAN"
                                      value={formData.tan}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Import Export Code(IEC)"
                                      value={formData.iec}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="PAN"
                                      value={formData.pan}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="CIN/LLPIN"
                                      value={formData.cin}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  onClick={handleNext}
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                              </div>
                            </TabPanel>
                            <TabPanel value="4">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Account Number"
                                      value={formData.accountnumber}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="A/c Holder Name"
                                      value={formData.holdername}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Re-enter Account Number"
                                      value={formData.reaccountnumber}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="IFSC Code"
                                      value={formData.ifsc}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Bank Name (As per IFSC Code)"
                                      value={formData.bankname}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      // style={{ marginTop: "20px" }}

                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Branch Name (As per IFSC Code)"
                                      value={formData.branchname}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      // style={{ marginTop: "20px" }}

                                      variant="outlined"
                                      margin="normal"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewprofile;
