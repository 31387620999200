import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { privateApiCall } from "../../api/privateApi";
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Box, TextField, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Chat = () => {
  const [users, setUsers] = useState([]); // Store an array of {userId, userName, chatId}
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false); // Loading state for messages
  const [messageInput, setMessageInput] = useState(""); // Input field for sending messages
  const cookies = new Cookies();
  const [org_id] = useState(cookies.get("org_id"));

  // Fetch users (members)
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await privateApiCall(`/api/organisation/getChatMembers/${org_id}`, "GET");
        const members = response.data.flatMap((chat) =>
          chat.members.map((member) => ({
            ...member,
            chatId: chat.chatId, // Include chatId from the chat object
          }))
        );
        setUsers(members);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [org_id]);

  // Fetch messages when a user is clicked
  const handleUserClick = async (chatId) => {
    setSelectedUser(chatId);
    setLoadingMessages(true);
    try {
      const response = await privateApiCall(`/api/organisation/getMessages/${chatId}`, "GET");
      console.log(response,"response data....")
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoadingMessages(false);
    }
  };
  console.log(messages,"messages")
  // Handle message input change
  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  
  const handleSendMessage = async () => {
    if (messageInput.trim()) {
      // Here you would send the message to the server
      const newMessage = {
        _id: new Date().getTime(),
        sender: "You", // In real scenario, this should be the user's ID
        text: messageInput,
        createdAt: new Date(),
      };
      setMessages([...messages, newMessage]);
      setMessageInput("");
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">Chat</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Chat</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            {/* Chat Layout */}
            <div className="row">
              <div className="col-xs-12 col-md-4">
                {/* User List */}
                <Box sx={{ width: 320, bgcolor: "background.paper" }}>
                  <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Chats
                  </Typography>
                  <List sx={{ padding: 0 }}>
                    {users.length > 0 ? (
                      users.map((user) => (
                        <ListItem
                          key={user.chatId}
                          onClick={() => handleUserClick(user.chatId)}
                          selected={selectedUser === user.chatId}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "#f0f0f0" },
                            padding: "10px 16px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar alt={user.userName} src={user.profilePic || "/defaultAvatar.png"} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={user.userName}
                           
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText primary="No users available" />
                      </ListItem>
                    )}
                  </List>
                </Box>
              </div>

              <div className="col-xs-12 col-md-8">
                {/* Chat Messages */}
                <div className="card" style={{ height: "70vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <div className="card-body" style={{ overflowY: "auto" }}>
                    <h5 className="card-title">Messages</h5>
                    {selectedUser ? (
                      loadingMessages ? (
                        <p>Loading messages...</p>
                      ) : messages.length > 0 ? (
                        messages.map((message) => (
                          <div
                            key={message._id}
                            style={{
                              display: "flex",
                              justifyContent: message.senderId === org_id ? "flex-end" : "flex-start",
                              margin: "10px 0",
                            }}
                          >
                            <div
                              style={{
                                maxWidth: "60%",
                                padding: "10px",
                                borderRadius: "10px",
                                backgroundColor:message.senderId === org_id  ? "#dcf8c6" : "#fff",
                                border: "1px solid #ddd",
                                boxShadow: "0px 1px 2px rgba(0,0,0,0.1)",
                              }}
                            >
                              <Typography variant="body2" color="textPrimary">
                                {message.text}
                              </Typography>
                              
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No messages yet</p>
                      )
                    ) : (
                      <p>Please select a user to view the chat</p>
                    )}
                  </div>

                  {/* Message Input */}
                  <div className="card-footer" style={{ padding: "10px", display: "flex", alignItems: "center" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Type a message"
                      value={messageInput}
                      onChange={handleInputChange}
                    />
                    <IconButton color="primary" onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
