import React from 'react';
import { Modal, Box, Grid, Typography, Button, Table, TableRow, TableCell } from '@mui/material';
import { formatDate } from '../../../utils/dateUtils';

const BuyviewModal = ({ open, handleClose, details, STRING_CONSTANTS }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          maxHeight: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Grid container justifyContent="center" alignItems="center" className="modal-content">
          <Grid item xs={12} className="modal-header">
            <Typography id="modal-title" variant="h6" component="h2">
              {STRING_CONSTANTS.DETAILS}
            </Typography>
            <Button onClick={handleClose} color="inherit">
              <span style={{ fontSize: "20px" }} aria-hidden="true">
                &#128473;
              </span>
            </Button>
          </Grid>

          <Grid item xs={12} className="modal-body">
            <Table className="col-12">
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.TITLE}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.DESCRIPTION}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.START_DATE}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {/* {details.startDate} */}
                  {formatDate(details.startDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.END_DATE}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {formatDate(details.endDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.LOCATION}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.location}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.LATITUDE}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.latitude}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.LONGITUDE}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.longitude}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.KEYWORDS}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.keywords ? details.keywords.join(", ") : `${STRING_CONSTANTS.KEYWORD_AVAILABLE}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.UNIT}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.unit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-left">{STRING_CONSTANTS.QUANTITY}</TableCell>
                <TableCell className="text-dark text-right font-weight-normal">
                  {details.qty}
                </TableCell>
              </TableRow>
            </Table>
          </Grid>

          <Grid item xs={12} className="modal-footer">
            <Button onClick={handleClose} variant="outlined" color="error">
              {STRING_CONSTANTS.CLOSE}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default BuyviewModal;
