import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"; // Adjust the import path for your UI library
import TabPanel from "@mui/lab/TabPanel"; // Adjust the import path for TabPanel if needed

const PaymentDetails = ({
  value,
  paymentDetails,
  formatDate,
  STRING_CONSTANTS,
}) => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  return (
    <TabPanel value={value}>
      <div style={{ position: "relative", padding: "10px" }}>
        <div
          style={{
            position: "absolute",
            top: "2px",
            right: "10px",
            width: "200px",
          }}
        >
          {(permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isSearch
            )) && (
            <>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                  className="form-control"
                  style={{
                    width: "100%",
                    paddingLeft: "35px",
                    boxSizing: "border-box",
                  }}
                  // onChange={handleSearchBuy} // Uncomment if you need search functionality
                />
                <i
                  className="fas fa-search"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#aaa",
                  }}
                ></i>
              </div>
            </>
          )}
        </div>

        <TableContainer style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                <TableCell>{STRING_CONSTANTS.PAYMENT_ID}</TableCell>
                <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                <TableCell>{STRING_CONSTANTS.CONTACT_NO}</TableCell>
                <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                <TableCell>{STRING_CONSTANTS.P_TYPE}</TableCell>
                <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentDetails.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{detail.payment_id}</TableCell>
                  <TableCell>{detail.name}</TableCell>
                  <TableCell>{detail.mobile_no}</TableCell>
                  <TableCell>{detail.amount}</TableCell>
                  <TableCell>{detail.type}</TableCell>
                  <TableCell>{formatDate(detail.time)}</TableCell>
                  <TableCell>{detail.payment_status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </TabPanel>
  );
};

export default PaymentDetails;
