import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import $ from "jquery";
import {
  getOrgFilters,
  deleteOrganisation,
  getChildOrganisation,
} from "../../actions/adminAction";
import { voucherList } from "../../actions/settingsAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import Cookies from "universal-cookie";
import { FiEdit2, FiEye } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

import {
  Table,
  Paper,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  TextField,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  BLOCK,
  EDIT,
  UNBLOCK,
  VIEW_DET,
  SEARCH_CHILD_ORG,
  EDIT_CHILD_ORG,
  ADD_CHILD_ORG,
} from "../../constants/url";
import { Tooltip } from "react-tooltip";
import {
  ERR_GETTING,
  ERR_POSTING,
  ERROR,
  ERR,
  CONFIRM,
  SUCC_ALERT_TITLE,
  ALERT_ICON_SUCC,
  RESTORE_ORG,
  ALERT_BUTTON_YES,
  REMOVE_ORG,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

const ManageOrganisation = () => {
  // const localRole = JSON.parse(localStorage.getItem("role"))
  // const permissions = localRole && localRole.permissions ? localRole.permissions : "All"
  const [details, setDetails] = useState({});
  const [values, setValues] = useState({
    name: "",
    displayName: "",
    state: "",
    place: "",
    district: "",
    pincode: "",
    file: "",
    mobile: "",
    email: "",
    password: "",
    person: "",
    error: "",
    org: [],
    vouchers: [],
    voucher_id: "",
    places: [],
    pincodes: [],
    header: "Add Organisation",
    type: "add",
    selected_place: "All",
    selected_pincode: "All",
    _id: "",
    org_id: cookies.get("org_id"),
    success: false,
    removed: false,
  });
  const {
    org_id,
    displayName,
    email,
    password,
    state,
    place,
    district,
    pincode,
    file,
    mobile,
    person,
    org,
    name,
    _id,
  } = values;
  const agritech_token = cookies.get("agritech_token");
  const [logo, setLogo] = useState("");
  const [imageURL, setImageURL] = useState("/assets/images/no_image.png");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [open1, setOpen1] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    voucherList("org")
      .then((vouch) => {
        if (vouch.error) {
          Swal.fire({
            title: vouch.error,
            icon: `${ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${CONFIRM}`,
          });
        } else {
          getOrgFilters(agritech_token).then((filt) => {
            if (filt.error) {
              Swal.fire({
                title: filt.error,
                icon: `${ERR}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${CONFIRM}`,
              });
            } else {
              getChildOrganisation(org_id)
                .then((data) => {
                  if (data.error) {
                    Swal.fire({
                      title: data.error,
                      icon: `${ERR}`,
                      allowOutsideClick: false,
                      showCancelButton: false,
                      confirmButtonText: `${CONFIRM}`,
                    });
                  } else {
                    $("#File1").val("");
                    $("#logo").val("");
                    setImageURL("/assets/images/no_image.png");
                    setLogo("");
                    setValues({
                      ...values,
                      vouchers: vouch.voucher,
                      places: filt.places,
                      pincodes: filt.pincodes,
                      name: "",
                      logo: "",
                      email: "",
                      file: "",
                      displayName: "",
                      person: "",
                      mobile: "",
                      state: "",
                      district: "",
                      place: "",
                      pincode: "",
                      org: data.org,
                      header: "Add Organisation",
                      _id: "",
                      type: "add",
                    });
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: `${ERR_GETTING}`,
                    icon: `${ERR}`,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: `${CONFIRM}`,
                  });
                });
            }
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${CONFIRM}`,
        });
      });
  };

  const handleClose2 = () => {
    setOpen1(false);
  };

  const handleClearData = () => {
    setValues({
      ...values,
      name: "",
      logo: "",
      email: "",
      password: "",
      file: "",
      displayName: "",
      person: "",
      mobile: "",
      state: "",
      district: "",
      place: "",
      pincode: "",
    });
  };
  const loadOrg = (name, value) => {
    if (name === "selected_pincode") {
      getChildOrganisation(org_id)
        .then((data) => {
          if (data.error) {
            Swal.fire({
              title: data.error,
              icon: `${ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${CONFIRM}`,
            });
          } else {
            $("#File1").val("");

            setValues({
              ...values,
              org: data.org,
              selected_pincode: data.selected_pincode,
              selected_place: data.selected_place,
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${CONFIRM}`,
          });
        });
    } else if (name === "selected_place") {
      getChildOrganisation(org_id)
        .then((data) => {
          if (data.error) {
            Swal.fire({
              title: data.error,
              icon: `${ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${CONFIRM}`,
            });
          } else {
            $("#File1").val("");

            setValues({
              ...values,
              org: data.org,
              selected_pincode: data.selected_pincode,
              selected_place: data.selected_place,
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${CONFIRM}`,
          });
        });
    } else {
      getChildOrganisation(org_id)
        .then((data) => {
          if (data.error) {
            Swal.fire({
              title: data.error,
              icon: `${ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${CONFIRM}`,
            });
          } else {
            $("#File1").val("");

            setValues({
              ...values,
              org: data.org,
              selected_pincode: data.selected_pincode,
              selected_place: data.selected_place,
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${CONFIRM}`,
          });
        });
    }
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SEARCH_CHILD_ORG}?org_id=${org_id}&query=${searchValue}`;

      const response = await privateApiCall(requestUrl, "GET");

      if (response.data && response.data.org) {
        setValues((prevState) => ({
          ...prevState,
          org: response.data.org,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          org: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERROR}`,
        text: `${ERR_GETTING}`,
        icon: `${ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${CONFIRM}`,
      });
    }
  };

  const [isGroup, setIsGroup] = React.useState(values.isGroup || false);

  const handleCheckboxChange = (event) => {
    setIsGroup(event.target.checked);
    setValues({ ...values, isGroup: event.target.checked });
  };

  const Actions = ({ cell, row }) => {
    const handleEditShow = (e) => {
      setShow1(true);
      setValues({
        ...values,
        error: false,
        name: row.name,
        displayName: row.displayName,
        pincode: row.pincode,
        state: row.state,
        person: row.person,
        place: row.place,
        mobile: row.mobile,
        district: row.district,
        email: row.email,
        password: row.password,
        logo: row.logo,
        file: row.file,
        isGroup: row.isGroup,
        header: "Edit Organisation",
        type: "edit",
        _id: row._id,
      });
      setIsGroup(row.isGroup);
    };
    const handleClick = (e) => {
      if (row.delete) {
        Swal.fire({
          title: `${RESTORE_ORG}${row.name} ?`,
          text: "",
          icon: "question",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              deleteOrganisation(row._id, false).then((data) => {
                if (data.error) {
                  setValues({ ...values, error: data.error, loading: false });
                } else {
                  loadData();
                }
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: `${ERR_POSTING}`,
              icon: `${ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${CONFIRM}`,
            });
          });
      } else {
        Swal.fire({
          title: `${REMOVE_ORG} ${row.name} ?`,
          text: "",
          icon: "question",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              deleteOrganisation(row._id, true).then((data) => {
                if (data.error) {
                  setValues({ ...values, error: data.error, loading: false });
                } else {
                  loadData();
                }
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: `${ERR_POSTING}`,
              icon: `${ERR}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${CONFIRM}`,
            });
          });
      }
    };

    const handleView = (e) => {
      setDetails(row);
      setOpen1(true);
    };

    return (
      <span>
        <i
          className="mr-2"
          title="Edit"
          data-tooltip-id="3"
          data-tooltip-content={`${EDIT}`}
          style={{ fontStyle: "normal" }}
          onClick={handleEditShow}
        >
          <FiEdit2 size="18px" color="#00C5B2" />
          <Tooltip id="3" />
        </i>
        {row.delete ? (
          <i
            className="me-2"
            onClick={handleClick}
            data-tooltip-id="1"
            data-tooltip-content={`${UNBLOCK}`}
            style={{ fontStyle: "normal" }}
          >
            <FaRegThumbsUp size="18px" color="green" />
            <Tooltip id="1" />
          </i>
        ) : (
          <i
            className="me-2"
            onClick={handleClick}
            data-tooltip-id="2"
            data-tooltip-content={`${BLOCK}`}
            style={{ fontStyle: "normal" }}
          >
            <FaRegThumbsDown size="18px" color="red" />
            <Tooltip id="2" />
          </i>
        )}

        <i
          className="ml-1"
          data-tooltip-id="4"
          data-tooltip-content={`${VIEW_DET}`}
        >
          {" "}
          <FiEye onClick={handleView} color="#9368f3" size="20" />
        </i>
        <Tooltip id="4" />
      </span>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("displayName", displayName);
    formData.append("person", person);
    formData.append("mobile", mobile);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("place", place);
    formData.append("pincode", pincode);
    formData.append("file", file);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("logo", logo);
    formData.append("parent_org", org_id);
    formData.append("isGroup", isGroup);

    const requestUrl = `${ADD_CHILD_ORG}`;
    privateApiCall(requestUrl, "POST", formData)
      .then((res) => {
        if (res.data.status === false) {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ERR}`,
            confirmButtonText: "Ok",
            showCancelButton: false,
          });
          handleClose(false);
          loadData();
        } else if (res.data.status === true) {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "Ok",
            showCancelButton: false,
          });
          handleClose(false);
          loadData();
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${CONFIRM}`,
        });
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("displayName", displayName);
    formData.append("person", person);
    formData.append("mobile", mobile);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("place", place);
    formData.append("pincode", pincode);
    formData.append("file", file);
    formData.append("email", email);
    if (password) {
      formData.append("password", password);
    }
    formData.append("logo", logo);
    formData.append("isGroup", values.isGroup);
    const requestUrl = `${EDIT_CHILD_ORG}?org_id=${_id}`;
    privateApiCall(requestUrl, "POST", formData).then((res) => {
      if (res.data.error) {
        Swal.fire({
          title: res.data.error,
          text: "",
          icon: `${ERR}`,
          confirmButtonText: `${CONFIRM}`,
          showCancelButton: false,
        });
      } else {
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          icon: `${ALERT_ICON_SUCC}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${CONFIRM}`,
        });
        handleClose1(false);
        loadData();

        setValues({
          ...values,
          name: "",
          displayName: "",
          person: "",
          mobile: "",
          state: "",
          district: "",
          place: "",
          pincode: "",
          file: "",
          email: "",
          password: "",
          logo: "",
        });
      }
    });
  };

  const onFileChange = (e) => {
    setValues({ ...values, error: false, file: e.target.files[0] });
  };
  const onLogoChange = (e) => {
    setLogo(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };
  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.MANAGE_ORG}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.MANAGE_ORG}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button onClick={handleShow}>
                        <span
                          className="btn  ml-2 mb-2"
                          onClick={(e) => handleClearData()}
                          style={{
                            float: "left",
                            backgroundColor: "#0baae5",
                            color: "#fff",
                            textTransform: "none",
                          }}
                        >
                          {" "}
                          <i
                            className="fa fa-plus"
                            style={{ marginRight: "5px" }}
                          ></i>{" "}
                          {STRING_CONSTANTS.ADD_ORG}
                        </span>
                      </Button>

                      <div style={{ position: "relative", marginLeft: "auto" }}>
                        <input
                          type="text"
                          placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                          className="form-control"
                          style={{
                            width: "200px",
                            paddingLeft: "35px",
                            boxSizing: "border-box",
                            marginLeft: "auto",
                          }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === "") {
                              e.target.nextElementSibling.style.display =
                                "inline-block";
                            } else {
                              e.target.nextElementSibling.style.display =
                                "none";
                            }
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#aaa",
                          }}
                        ></i>
                      </div>
                    </div>

                    <Modal
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="add-organisation-modal-title"
                      aria-describedby="add-organisation-modal-description"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "100px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "950px",
                          backgroundColor: "white",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow: 24,
                          outline: "none",
                        }}
                      >
                        <h4 id="add-organisation-modal-title">
                          {STRING_CONSTANTS.ADD_ORG}
                        </h4>
                        <form onSubmit={handleSubmit}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isGroup}
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label="Group"
                              sx={{ marginBottom: "16px", marginTop: "20px" }}
                            />
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Name"
                                type="text"
                                value={name}
                                onChange={handleChange("name")}
                                placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Display Name"
                                type="text"
                                value={displayName}
                                onChange={handleChange("displayName")}
                                placeholder={STRING_CONSTANTS.DISPLAY_NAME_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Email ID"
                                type="email"
                                value={email}
                                onChange={handleChange("email")}
                                placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Contact Person"
                                type="text"
                                value={person}
                                onChange={handleChange("person")}
                                placeholder={STRING_CONSTANTS.CONTACT_PERSON_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Mobile Number"
                                type="tel"
                                value={mobile}
                                onChange={handleChange("mobile")}
                                placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handleChange("password")}
                                placeholder={STRING_CONSTANTS.PASSWORD_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="State"
                                type="text"
                                value={state}
                                onChange={handleChange("state")}
                                placeholder={STRING_CONSTANTS.STATE_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="District"
                                type="text"
                                value={district}
                                onChange={handleChange("district")}
                                placeholder={STRING_CONSTANTS.DISTRICT_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Place"
                                type="text"
                                value={place}
                                onChange={handleChange("place")}
                                placeholder={STRING_CONSTANTS.PLACE_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Pincode"
                                type="text"
                                value={pincode}
                                onChange={handleChange("pincode")}
                                placeholder={STRING_CONSTANTS.PINCODE_PLACEHOLDER}
                                fullWidth
                                required
                                sx={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <input
                                type="file"
                                id="File1"
                                file={file}
                                onChange={onFileChange}
                                accept="application/pdf"
                                style={{ marginBottom: "16px" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <input
                                type="file"
                                id="logo"
                                onChange={onLogoChange}
                                style={{ marginBottom: "16px" }}
                              />
                              <img
                                src={imageURL}
                                alt="Logo"
                                className="rounded-circle avatar-xl"
                                style={{ marginBottom: "16px" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="flex-end"
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "#0baae5",
                                  color: "#fff",
                                }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Modal>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px" align="center">
                             {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell width="80px" align="center">
                            {STRING_CONSTANTS.LOGO}
                            </TableCell>
                            <TableCell width="80px" align="center">
                            {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                            {STRING_CONSTANTS.DISPLAY_NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                            {STRING_CONSTANTS.EMAIL}
                            </TableCell>

                            <TableCell width="80px" align="center">
                             {STRING_CONSTANTS.CONTACT_PERSON}
                            </TableCell>

                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.STATUS}
                            </TableCell>

                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {org.map((org, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{index + 1}</TableCell>

                              <TableCell align="center">
                                <img
                                  src={
                                    org.logo === ""
                                      ? "/assets/images/no_image.png"
                                      : org.logo
                                  }
                                  alt="Logo"
                                  style={{ maxWidth: 50 }}
                                />
                              </TableCell>
                              <TableCell align="center">{org.name}</TableCell>
                              <TableCell align="center">
                                {org.displayName}
                              </TableCell>
                              <TableCell align="center">{org.email}</TableCell>

                              <TableCell align="center">{org.person}</TableCell>
                              <TableCell align="center">
                                {" "}
                                {org.status === true ? (
                                  <span className="btn-sm btn-danger ">
                                    {STRING_CONSTANTS.INACTIVE}
                                  </span>
                                ) : (
                                  <span className="btn-sm btn-success">
                                      {STRING_CONSTANTS.ACTIVE}
                                  </span>
                                )}{" "}
                              </TableCell>

                              <TableCell align="center">
                                <Actions row={org} />
                              </TableCell>
                            </TableRow>
                          ))}
                          <Modal
                            open={show1}
                            onClose={handleClose1}
                            aria-labelledby="add-organisation-modal-title"
                            aria-describedby="add-organisation-modal-description"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "100px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "950px",
                                backgroundColor: "white",
                                padding: "20px",
                                borderRadius: "8px",
                                boxShadow: 24,
                                outline: "none",
                              }}
                            >
                              <h4 id="add-organisation-modal-title">
                                {STRING_CONSTANTS.EDIT_ORG}
                              </h4>
                              <form onSubmit={handleEditSubmit}>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.isGroup}
                                        onChange={handleCheckboxChange}
                                      />
                                    }
                                    label="Group"
                                    sx={{
                                      marginBottom: "16px",
                                      marginTop: "20px",
                                    }}
                                  />
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Name"
                                      type="text"
                                      value={name}
                                      onChange={handleChange("name")}
                                      placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Display Name"
                                      type="text"
                                      value={displayName}
                                      onChange={handleChange("displayName")}
                                      placeholder={STRING_CONSTANTS.DISPLAY_NAME_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Email ID"
                                      type="email"
                                      value={email}
                                      onChange={handleChange("email")}
                                      placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Contact Person"
                                      type="text"
                                      value={person}
                                      onChange={handleChange("person")}
                                      placeholder={STRING_CONSTANTS.CONTACT_PERSON_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Mobile Number"
                                      type="tel"
                                      value={mobile}
                                      onChange={handleChange("mobile")}
                                      placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Password"
                                      type="password"
                                      value={password}
                                      onChange={handleChange("password")}
                                      placeholder={STRING_CONSTANTS.RESET_PASSWORD}
                                      fullWidth
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="State"
                                      type="text"
                                      value={state}
                                      onChange={handleChange("state")}
                                      placeholder={STRING_CONSTANTS.STATE_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="District"
                                      type="text"
                                      value={district}
                                      onChange={handleChange("district")}
                                      placeholder={STRING_CONSTANTS.DISTRICT_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Place"
                                      type="text"
                                      value={place}
                                      onChange={handleChange("place")}
                                      placeholder={STRING_CONSTANTS.PLACE_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Pincode"
                                      type="text"
                                      value={pincode}
                                      onChange={handleChange("pincode")}
                                      placeholder={STRING_CONSTANTS.PINCODE_PLACEHOLDER}
                                      fullWidth
                                      required
                                      sx={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <input
                                      type="file"
                                      id="File1"
                                      file={file}
                                      onChange={onFileChange}
                                      accept="application/pdf"
                                      style={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <input
                                      type="file"
                                      id="logo"
                                      onChange={onLogoChange}
                                      style={{ marginBottom: "16px" }}
                                    />
                                    <img
                                      src={imageURL}
                                      alt="Logo"
                                      className="rounded-circle avatar-xl"
                                      style={{ marginBottom: "16px" }}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="flex-end"
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose1}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#0baae5",
                                        color: "#fff",
                                      }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </Box>
                          </Modal>

                          <Dialog
                            open={open1}
                            onClose={handleClose2}
                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                              marginTop: "100px",
                            }}
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <h5 id="details-modal-title">Details</h5>
                                <Button onClick={handleClose2} color="inherit">
                                  {" "}
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                  <TableRow>
                                    <TableCell className="text-left">
                                     {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.mobile}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.STATE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.state}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                  {STRING_CONSTANTS.DISTRICT}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.district}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                    {STRING_CONSTANTS.PLACE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.place}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.PINCODE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.pincode}
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </Grid>
                              <Grid item xs={12} className="modal-footer">
                                <Button
                                  onClick={handleClose2}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Dialog>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageOrganisation;
