import React, { Component } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  GET_PROFILE_PATH,
  EDIT_PROFILE_PATH,
  EDIT_IMAGE_PATH,
} from "../../constants/url";
import { FaArrowLeft } from "react-icons/fa";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_EDIT_PROFILE,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

class Editprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      currentPassword: "",
      newPassword: "",
      retypeNewPassword: "",
      name: "",
      showPasswordFields: false,
      image: "http://placehold.it/60/c2c2c2?text=User",
      selectedImage: null,
      imagePreview: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePasswordFields = this.togglePasswordFields.bind(this);
  }

  componentDidMount() {
    if (
      cookies.get("login_type") !== "callSupport" &&
      cookies.get("login_type") !== "organisation"
    ) {
      const requestUrl = `${GET_PROFILE_PATH}/${cookies.get("agritech_id")}/`;
      privateApiCall(requestUrl, "GET")
        .then((res) => {
          const adminData = res.data.admin[0];
          this.setState({
            name: adminData.name || "",
            username: adminData.username || "",
            currentPassword: "",
            image: adminData.image || "http://placehold.it/60/c2c2c2?text=User",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        });
    }
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleImageChange(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          selectedImage: file,
          imagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        selectedImage: null,
        imagePreview: null,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  
    const {
      name,
      currentPassword,
      newPassword,
      retypeNewPassword,
      showPasswordFields,
      selectedImage,
    } = this.state;
  
    if (showPasswordFields) {
      // Ensure that all password fields are filled and valid
      if (!currentPassword || !newPassword || !retypeNewPassword) {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          text: "Please fill all the password fields.",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        return;
      }
  
      if (newPassword !== retypeNewPassword) {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          text: "Passwords do not match.",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        return;
      }
    }
  
    // Handle Image upload if an image is selected
    if (selectedImage !== null) {
      const imageFormData = new FormData();
      imageFormData.append("image", selectedImage);
      const requestUrl = `${EDIT_IMAGE_PATH}/${cookies.get("agritech_id")}/`;
      privateApiCall(requestUrl, "POST", imageFormData)
        .then(() => {
          // Image upload successful, proceed with profile update
          this.updateProfile(name, currentPassword, newPassword);
        })
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    } else {
      // No image selected, just update profile
      this.updateProfile(name, currentPassword, newPassword);
    }
  }
  
  updateProfile(name, currentPassword = "", newPassword = "") {
    const jwtToken = cookies.get("jwtToken");
    const profileData = { name, currentPassword, newPassword, jwtToken };
  
    const requestUrl = `${EDIT_PROFILE_PATH}/${cookies.get("agritech_id")}/`;
    privateApiCall(requestUrl, "POST", profileData)
      .then((res) => {
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: `${SUCC_EDIT_PROFILE}`,
          icon: `${ALERT_ICON_SUCC}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/dashboard";
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }
  

  togglePasswordFields() {
    this.setState((prevState) => ({
      showPasswordFields: !prevState.showPasswordFields,
      currentPassword: prevState.showPasswordFields
        ? ""
        : this.state.currentPassword,
    }));
  }

  render() {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" /> <Sidebar type="profiles" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">{STRING_CONSTANTS.EDIT_PROFILE}</h4>
                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.EDIT_PROFILE}</li>
                    </ol>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="card-box">
                      <div className="row">
                        <div className="float-right">
                          <Link to="/dashboard">
                            <button
                              type="button"
                              className="btn btn-primary ml-2 mb-3"
                            >
                              <FaArrowLeft /> {STRING_CONSTANTS.BACK}
                            </button>
                          </Link>
                        </div>
                        <div className="col-12">
                          <div className="p-20">
                            <div className="mb-4">
                              <img
                                src={
                                  this.state.imagePreview || this.state.image
                                }
                                alt="Profile"
                                className="rounded-circle mr-3"
                                style={{
                                  width: "130px",
                                  height: "130px",
                                  border: "2px solid #769A0F",
                                  float: "left",
                                }}
                              />

                              <div style={{ clear: "both" }}></div>
                            </div>
                            <label>{STRING_CONSTANTS.UPDATE_PROFLE_PIC}</label>
                            <div className="form-group row">
                              <div className="col-12">
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  onChange={this.handleImageChange}
                                />
                              </div>
                            </div>
                            <label>{STRING_CONSTANTS.NAME}</label>
                            <div className="form-group row">
                              <div className="col-12">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                  value={this.state.name}
                                  required
                                />
                              </div>
                            </div>
                            <label>{STRING_CONSTANTS.USER_NAME}</label>
                            <div className="form-group row">
                              <div className="col-12">
                                <input
                                  type="email"
                                  id="example-email"
                                  className="form-control"
                                  name="username"
                                  value={this.state.username}
                                  readOnly
                                />
                              </div>
                            </div>
                            {this.state.showPasswordFields && (
                              <>
                                <label>{STRING_CONSTANTS.CURRENT_PASSWORD}</label>
                                <div className="form-group row">
                                  <div className="col-12">
                                    <input
                                      type="password"
                                      name="currentPassword"
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                      value={this.state.currentPassword}
                                      required
                                    />
                                  </div>
                                </div>
                                <label>{STRING_CONSTANTS.NEW_PASSWORD}</label>
                                <div className="form-group row">
                                  <div className="col-12">
                                    <input
                                      type="password"
                                      name="newPassword"
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                      value={this.state.newPassword}
                                      required
                                    />
                                  </div>
                                </div>
                                <label>{STRING_CONSTANTS.CONFIRM_PASSWORD}</label>
                                <div className="form-group row">
                                  <div className="col-12">
                                    <input
                                      type="password"
                                      name="retypeNewPassword"
                                      className="form-control"
                                      onChange={this.handleInputChange}
                                      value={this.state.retypeNewPassword}
                                      required
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <button type="submit" className="btn btn-primary">
                              {STRING_CONSTANTS.SUBMIT}
                            </button>
                            {!this.state.showPasswordFields && (
                              <button
                                type="button"
                                className="btn btn-secondary ml-2"
                                onClick={this.togglePasswordFields}
                              >
                               {STRING_CONSTANTS.CHANGE_PASSWORD}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Editprofile;
