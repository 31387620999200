import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { privateApiCall } from "../api/privateApi";
import { getOrganisation } from "../actions/adminAction";
import Cookies from "universal-cookie";
import {
  Modal,
  Box,
  Typography,
  Button,
  TableCell,
  Grid,
  Table,
  TableRow,
  TablePagination,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FiEye } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { ADD_EMPLOYEE, VIEW_DET } from "../constants/url";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const cookies = new Cookies();
const agritech_token = cookies.get("agritech_token");

const HarvestPlanner = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPeriodType, setSelectedPeriodType] = useState("");
  const [selectedVariety, setSelectedVariety] = useState("");
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedHarvest, setSelectedHarvest] = useState(null);
  const [variety, setVariety] = useState([]);
  const [orgName, setOrgName] = useState([]);
  const [harvestUser, setHarvestUser] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    getHarvest(event.target.value);
  };

  const handleViewDetails = (harvest) => {
    setSelectedHarvest(harvest);
    setDetailsModalOpen(true);
  };

  const handleCloseModal = () => {
    setDetailsModalOpen(false);
    setSelectedHarvest(null);
  };

  const handlePeriodTypeChange = (e) => {
    setSelectedPeriodType(e.target.value);
  };

  const handleVarietyChange = (e) => {
    setSelectedVariety(e.target.value);
  };
  const handleSearchTermChange = async (e) => {
    try {
      const value = e.target.value;
      setSearchTerm(value);
      if (value === "") {
        getHarvest();
        return;
      }

      const requestUrl = `/app/searchHarvestData/${value}`;
      const result = await privateApiCall(requestUrl, "GET");
      if (result.data.data.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
        setHarvestUser(result.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredHarvests = harvestUser.filter((harvest) => {
    const matchesSearchTerm = searchTerm === "" || harvestUser;
    const matchesPeriodType =
      selectedPeriodType === "" || harvest.periodType === selectedPeriodType;
    const matchesVariety =
      selectedVariety === "" ||
      (harvest.variety && harvest.variety.variety === selectedVariety);
    return matchesSearchTerm && matchesPeriodType && matchesVariety;
  });

  const paginatedHarvests = filteredHarvests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const loadOrganisation = () => {
    getOrganisation(agritech_token, "All", "All").then((data) => {
      const name = data.org.map((org) => org.displayName);
      setOrgName(name);
    });
  };

  const getHarvest = async (organisation = "") => {
    const requestUrl = `${ADD_EMPLOYEE}/get_harvest?organisation=${organisation}`;
    try {
      const response = await privateApiCall(requestUrl, "GET");
      setHarvestUser(response.data.data.org_user);
      setVariety(response.data.data.varieties);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadOrganisation();
    getHarvest();
  }, []);

  const handleCardClick = (index) => {
    if (selectedCard !== index) {
      setSelectedCard(index);
    }
  };
  const groupedHarvests = harvestUser.reduce((acc, harvest) => {
    acc[harvest.userName] = acc[harvest.userName] || [];
    acc[harvest.userName].push(harvest);
    return acc;
  }, {});

  const groupedHarvestCount = Object.keys(groupedHarvests).length;

  const [open, setOpen] = useState({});

  const handleToggle = (userName) => {
    setOpen((prevOpen) => ({ ...prevOpen, [userName]: !prevOpen[userName] }));
  };
  const groupedFilteredHarvests = filteredHarvests.reduce((acc, harvest) => {
    acc[harvest.userName] = acc[harvest.userName] || [];
    acc[harvest.userName].push(harvest);
    return acc;
  }, {});

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div
        style={{ padding: "20px", backgroundColor: "#f7f8fa" }}
        className="content-page"
      >
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div
                  className="page-title-box"
                  style={{ marginBottom: "20px" }}
                >
                  <h4 className="page-title float-left">Harvest Planner</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Harvest Planner</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {[
                    {
                      name: "Jackfruit",
                      image: "/assets/images/harvest/jackfruit.png",
                    },
                    {
                      name: "Rambuttan",
                      image: "/assets/images/harvest/rambuttan.png",
                    },
                    {
                      name: "Avocado",
                      image: "/assets/images/harvest/avacado.png",
                    },
                    {
                      name: "Pappaya",
                      image: "/assets/images/harvest/pappaya.png",
                    },
                    {
                      name: "Pineapple",
                      image: "/assets/images/harvest/p.png",
                    },
                    {
                      name: "Dragon fruit",
                      image: "/assets/images/harvest/dragon.png",
                    },
                  ].map((item, index) => (
                    <div
                      className="progress-card"
                      key={index}
                      style={{
                        background:
                          selectedCard === index ? "#9fb857" : "#eef7f9",
                        borderRadius: "8px",
                        padding: "20px",
                        textAlign: "center",
                        marginBottom: "20px",
                        flex: "0 0 calc(16.66% - 20px)",
                        margin: "10px",
                        transform:
                          hoveredCard === index ? "scale(1.05)" : "scale(1)",
                        transition: "transform 0.2s",
                        zIndex: hoveredCard === index ? "10" : "1",
                        cursor: index === 0 ? "pointer" : "default",
                      }}
                      onClick={() => index === 0 && handleCardClick(index)}
                      onMouseOver={() => index === 0 && setHoveredCard(index)}
                      onMouseOut={() => index === 0 && setHoveredCard(null)}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: "100px", height: "120px" }}
                      />
                      <p style={{ color: "black" }}>{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  {permissions.some(
                    (item) =>
                      item.keyword === "harvestplanner" && item.isSort === true
                  ) && (
                    <>
                      <select
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        style={{
                          padding: "10px",
                          width: "40%",
                          borderRadius: "4px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <option value="">All</option>
                        {orgName.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="card-box"
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                  }}
                >
                  <h4 className="header-title mb-4">
                    {STRING_CONSTANTS.HARVEST_DATA}
                  </h4>
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {permissions.some(
                      (item) =>
                        item.keyword === "harvestplanner" &&
                        item.isSearch === true
                    ) && (
                      <>
                        <input
                          type="text"
                          placeholder="Search "
                          onChange={handleSearchTermChange}
                          style={{
                            padding: "10px",
                            width: "40%",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                            marginRight: "4px",
                          }}
                        />
                      </>
                    )}

                    {permissions.some(
                      (item) =>
                        item.keyword === "harvestplanner" &&
                        item.isSort === true
                    ) && (
                      <>
                        <select
                          value={selectedPeriodType}
                          onChange={handlePeriodTypeChange}
                          style={{
                            padding: "10px",
                            width: "28%",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                          }}
                        >
                          <option value="">
                            {" "}
                            {STRING_CONSTANTS.ALL_PERIOD_TYPES}
                          </option>
                          <option value="Seasonal">
                            {" "}
                            {STRING_CONSTANTS.SEASONAL}
                          </option>
                          <option value="Weekly">
                            {STRING_CONSTANTS.WEEKLY}
                          </option>
                          <option value="Monthly">
                            {STRING_CONSTANTS.MONTHLY}
                          </option>
                        </select>
                        <select
                          value={selectedVariety}
                          onChange={handleVarietyChange}
                          style={{
                            padding: "10px",
                            width: "28%",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                          }}
                        >
                          <option value="">
                            {" "}
                            {STRING_CONSTANTS.ALL_VARIETY_TYPES}
                          </option>
                          {variety.map((v, index) => (
                            <option key={index} value={v.variety}>
                              {v.variety}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                  <div className="table-responsive">
                    {noDataFound ? (
                      <p>
                        {STRING_CONSTANTS.NO_HARVEST_DATA_FOUND} '{searchTerm}'
                      </p>
                    ) : (
                      <table className="table table-hover table-centered mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th>{STRING_CONSTANTS.SN_NO}</th>
                            <th>{STRING_CONSTANTS.USER_NAME}</th>
                            <th>{STRING_CONSTANTS.PERIOD_TYPE}</th>
                            <th>{STRING_CONSTANTS.START_DATE}</th>
                            <th>{STRING_CONSTANTS.VARIETY}</th>
                            <th>{STRING_CONSTANTS.ACTIONS}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(groupedFilteredHarvests).map(
                            (userName, index) => (
                              <React.Fragment key={userName}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {userName}
                                    {groupedFilteredHarvests[userName].length >
                                      1 && (
                                      <IconButton
                                        onClick={() => handleToggle(userName)}
                                      >
                                        {open[userName] ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    )}
                                  </td>
                                  <td>
                                    {
                                      groupedFilteredHarvests[userName][0]
                                        .periodType
                                    }
                                  </td>
                                  <td>
                                    {
                                      groupedFilteredHarvests[userName][0]
                                        .startMonth
                                    }
                                  </td>
                                  <td>
                                    {groupedFilteredHarvests[userName][0]
                                      .variety
                                      ? groupedFilteredHarvests[userName][0]
                                          .variety.variety
                                      : ""}
                                  </td>
                                  <td>
                                    <i
                                      data-tooltip-id="2"
                                      data-tooltip-content={`${VIEW_DET}`}
                                    >
                                      <FiEye
                                        variant="contained"
                                        onClick={() =>
                                          handleViewDetails(
                                            groupedFilteredHarvests[userName][0]
                                          )
                                        }
                                        color="#9368f3"
                                        size="20"
                                      />
                                    </i>
                                    <Tooltip
                                      id="2"
                                      style={{ fontStyle: "normal" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    colSpan={6}
                                  >
                                    <Collapse
                                      in={open[userName]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        <Typography
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          {STRING_CONSTANTS.DETAILS}
                                        </Typography>
                                        <table className="table table-hover table-centered mb-0">
                                          <thead className="thead-light">
                                            <tr>
                                              <th>
                                                {STRING_CONSTANTS.PERIOD_TYPE}
                                              </th>
                                              <th>
                                                {STRING_CONSTANTS.START_DATE}
                                              </th>
                                              <th>
                                                {STRING_CONSTANTS.VARIETY}
                                              </th>
                                              <th>
                                                {STRING_CONSTANTS.ACTIONS}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {groupedFilteredHarvests[
                                              userName
                                            ].map((harvest, i) => (
                                              <tr key={i}>
                                                <td>{harvest.periodType}</td>
                                                <td>{harvest.startMonth}</td>
                                                <td>
                                                  {harvest.variety
                                                    ? harvest.variety.variety
                                                    : ""}
                                                </td>
                                                <td>
                                                  <i
                                                    data-tooltip-id="2"
                                                    data-tooltip-content={`${VIEW_DET}`}
                                                  >
                                                    <FiEye
                                                      variant="contained"
                                                      onClick={() =>
                                                        handleViewDetails(
                                                          harvest
                                                        )
                                                      }
                                                      color="#9368f3"
                                                      size="20"
                                                    />
                                                  </i>
                                                  <Tooltip
                                                    id="2"
                                                    style={{
                                                      fontStyle: "normal",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </Box>
                                    </Collapse>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          )}
                        </tbody>

                        <Modal
                          open={detailsModalOpen}
                          onClose={handleCloseModal}
                          aria-labelledby="modal-title"
                          aria-describedby="modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "80vw",
                              maxHeight: "80vh",
                              bgcolor: "background.paper",
                              boxShadow: 24,
                              p: 4,
                              overflowY: "auto",
                            }}
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid
                                item
                                xs={12}
                                className="modal-header"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  id="modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {STRING_CONSTANTS.DETAILS}
                                </Typography>
                                <Button
                                  onClick={handleCloseModal}
                                  color="inherit"
                                >
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                {selectedHarvest && (
                                  <Table className="col-12">
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.USER_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.userName}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.PERIOD_TYPE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.periodType}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.START_MONTH}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.startMonth}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.END_MONTH}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.endMonth}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.WEEK}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.week}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.VARIETY}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.variety.variety}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.HARVEST_STAGE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.harvestingStage}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.INCOME_PRIMARY_TENDER}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.incomePrimaryTendor}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.INCOME_TENDER}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.incomeTendor}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {
                                          STRING_CONSTANTS.INCOME_GREEN_JACKFRUIT
                                        }
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.incomeGreenJackfruit}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.INCOME_MATURED}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {selectedHarvest.incomeMatured}
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className="modal-footer"
                                sx={{
                                  display: "flex",

                                  mt: 2,
                                }}
                              >
                                <Button
                                  onClick={handleCloseModal}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </table>
                    )}
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={groupedHarvestCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarvestPlanner;
