import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  getRoles,
  getPortalUsers,
  savePortalUser,
} from "../../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Grid,
  Button,
  Modal,
  TextField,
} from "@mui/material"; // Import MUI component
import {
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  ALERT_ICON_SUCC,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Users = () => {
  const [roles, setRoles] = useState([]);
  const [portalUsers, setPortalUsers] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [portalUserId, setPortalUserId] = useState(null);
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    email: "",
    role: "",
    header: "",
    type: "",
    _id: "",
    success: false,
    removed: false,
    loading: false,
  });
  const { email, mobile, name } = values;

  useEffect(() => {
    fetchRoles();
    fetchPortalUsers();
  }, []);

  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      if (data.error) {
        throw new Error();
      }
      setRoles(data.roles);
    } catch {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`
      });
    }
  };

  const fetchPortalUsers = async () => {
    try {
      const data = await getPortalUsers();
      if (data.error) {
        throw new Error();
      }
      setPortalUsers(data.portalUsers);
    } catch {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`
      });
    }
  };

  const handleClearData = () => {
    setValues({
      ...values,
      role: "",
      name: "",
      email: "",
      mobile: "",
    });
    setShow(true);
    setPortalUserId(null);
    setSelectedRoleId("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true, error: false });
    const userData = { name, email, mobile, roleId: selectedRoleId };
    savePortalUser(userData)
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`
          });
        } else {
          handleClose(false);
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          }).then(() => {
            fetchPortalUsers();
            handleClearData();
            setShow(false);
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      });
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const getRoleNameById = (roleId) => {
    const role = roles.find((role) => role.roleId === roleId);
    return role ? role.name : `${STRING_CONSTANTS.ROLE_NOT_FOUND}`
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.PORTAL_USERS}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.PORTAL_USERS}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary"
                        onClick={handleClearData}
                        style={{ marginRight: "10px" }}
                      >
                        <i className="fa fa-plus"></i> {STRING_CONSTANTS.ADD_PROTAL_USERS}
                      </button>
                      <div
                        style={{ position: "relative", marginLeft: "auto" }}
                      ></div>
                    </div>

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ minWidth: 100 }}>
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {STRING_CONSTANTS.ROLE}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {portalUsers &&
                            portalUsers.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>
                                  {getRoleNameById(row.roleId)}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Modal
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="add-call-support-modal-title"
                      aria-describedby="add-call-support-modal-description"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "900px",
                          height: "300px",
                          backgroundColor: "white",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow: 24,
                          outline: "none",
                        }}
                      >
                        <h3>
                          {portalUserId
                            ? `${STRING_CONSTANTS.EDIT_PROTAL_USER}`
                            : `${STRING_CONSTANTS.ADD_PROTAL_USERS}`}
                        </h3>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Name"
                                value={name}
                                placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                                onChange={handleChange("name")}
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Email ID"
                                value={email}
                                placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                                onChange={handleChange("email")}
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label="Mobile No"
                                value={mobile}
                                placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                                onChange={handleChange("mobile")}
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="role-label">
                                  {STRING_CONSTANTS.SELECT_ROLE}
                                </InputLabel>
                                <Select
                                  labelId="role-label"
                                  value={selectedRoleId}
                                  onChange={(e) =>
                                    setSelectedRoleId(e.target.value)
                                  }
                                  required
                                >
                                  <MenuItem value="">
                                    <em>{STRING_CONSTANTS.SELECT_ROLE}</em>
                                  </MenuItem>
                                  {roles
                                    .sort((a, b) => a.roleId - b.roleId).map((item) => (item.roleId != 5 &&
                                      <MenuItem
                                        key={item.roleId}
                                        value={item.roleId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="flex-end"
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "#769A0F",
                                  color: "#fff",
                                }}
                              >
                                {portalUserId ? "Update" : "Submit"}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
