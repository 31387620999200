import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Tooltip } from "react-tooltip";
import { IoSearch } from "react-icons/io5";
import {
  ALERT_BUTTON_NO,
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  DELETE_YES_BUTTON,
  DO_YOU_WANT_TO_DELETE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_DELETE,
} from "../../constants/alertMessage";
import { EDIT, LANGUAGE, ACTIVE, DEACT } from "../../constants/url";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Button,
  TextField,
  Grid,
  Fade,
  Box,
} from "@mui/material";
import { API as url1 } from "../../config";
import Cookies from "universal-cookie";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Language = () => {
  const [show, setShow] = useState(false);
  const [noData, setNoData] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    _id: "",
    language: "",
    code: "",
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const jwtToken = cookies.get("jwtToken");

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { language: formData.language, code: formData.code };

    const requestUrl = `${LANGUAGE}/addlanguage`;
    privateApiCall(requestUrl, "POST", body)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: response.data.message,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });

          fetchLanguage();

          setFormData({
            language: "",
            code: "",
          });
        } else {
          console.log(response);
        }
        setShow(false);
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });

        setShow(false);
      });
  };

  useEffect(() => {
    fetchLanguage();
  }, []);

  const fetchLanguage = () => {
    const requestUrl = `${LANGUAGE}/getlanguage`;
    privateApiCall(requestUrl, "GET")
      .then((response) => {
        const responseData = response.data;
        if (responseData && responseData.data) {
          setData(responseData.data);
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `error`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleEdit = (item) => {
    setShowEditModal(true);
    setFormData({
      ...formData,
      _id: item._id,
      language: item.language,
      code: item.code,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const languageId = formData._id;
      const requestUrl = `${LANGUAGE}/updateLanguage?languageId=${languageId}`;
      const response = await privateApiCall(requestUrl, "PUT", formData);
      if (response.data.status) {
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
          icon: "success",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });

        fetchLanguage();
        setFormData({
          language: "",
          code: "",
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
      setShowEditModal(false);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `error`,
        text: `${ERR_POSTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleDisable = async (item) => {
    try {
      const result = await Swal.fire({
        title: `${STRING_CONSTANTS.DO_YOU_WANT_TO} ${
          item.status === "active"
            ? `${STRING_CONSTANTS.DISABLE}`
            : `${STRING_CONSTANTS.ENABLE}`
        } the ${item.language}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      });

      if (result.isConfirmed) {
        const requestUrl = `${LANGUAGE}/softDeleteLanguage?languageId=${item._id}`;

        const response = await privateApiCall(requestUrl, "PUT", {});

        if (response.data.status) {
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${STRING_CONSTANTS.LANGUAGE} ${
              item.status === "active"
                ? `${STRING_CONSTANTS.DISABLE}`
                : `${STRING_CONSTANTS.ENABLE}`
            } ${STRING_CONSTANTS.SUCCESSFULLY}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_YES}`,
          });

          fetchLanguage();
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            text: response.data.message,
            icon: "error",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `error`,
        text: `${ERR_POSTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        title: ` ${DO_YOU_WANT_TO_DELETE} ${item.language}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: " #3085d6",
        confirmButtonText: `${DELETE_YES_BUTTON}`,
      });

      if (result.isConfirmed) {
        const requestUrl = `${LANGUAGE}/${item._id}`;

        const response = await privateApiCall(requestUrl, "DELETE", {});
        if (response.data.status) {
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${item.language} ${SUCC_DELETE}`,
            icon: "success",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });

          fetchLanguage();
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            text: response.data.message,
            icon: "error",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: "error",
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const actionFormatter = (item) => (
    <span>
      {permissions.some(
        (item) => item.keyword === "settingsLanguages" && item.isEdit === true
      ) && (
        <>
          <FiEdit2
            size="18px"
            type="button"
            data-tooltip-id="1"
            data-tooltip-content={`${EDIT}`}
            color="#0978F2"
            onClick={() => handleEdit(item)}
          ></FiEdit2>
          <Tooltip id="1" />
        </>
      )}

      <i onClick={() => handleDisable(item)}>
        {item.status === "active"
          ? permissions.some(
              (item) =>
                item.keyword === "settingsLanguages" && item.isBlock === true
            ) && (
              <>
                <FaRegThumbsDown
                  size="18px"
                  color="red"
                  data-tooltip-id="2"
                  data-tooltip-content={`${DEACT}`}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </>
            )
          : permissions.some(
              (item) =>
                item.keyword === "settingsLanguages" && item.isUnblock === true
            ) && (
              <>
                <FaRegThumbsUp
                  size="18px"
                  color="green"
                  data-tooltip-id="3"
                  data-tooltip-content={`${ACTIVE}`}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </>
            )}
        <Tooltip id="2" style={{ fontStyle: "normal" }} />
        <Tooltip id="3" style={{ fontStyle: "normal" }} />
      </i>
      {permissions.some(
        (item) => item.keyword === "settingsFaq" && item.isDelete === true
      ) && (
        <>
          <i onClick={() => handleDelete(item)}>
            <FiTrash2
              size="18px"
              color="red"
              data-tooltip-id="4"
              data-tooltip-content="Delete"
              style={{ marginLeft: "10px", cursor: "pointer" }}
            />
            <Tooltip id="4" style={{ fontStyle: "normal" }} />
          </i>
        </>
      )}
    </span>
  );

  const handleSearch = async (e) => {
    try {
      const val = e.target.value;
      if (val === "") {
        fetchLanguage();
        setNoData(false); // Reset no data state
        return;
      }
      const result = await axios.get(`${url1}/api/language/search/${val}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setData(result.data.language);
      setNoData(result.data.language.length === 0);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNoData(true);
      } else {
        console.error(error);
        setNoData(false);
      }
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `error`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="settings" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.SETTINGS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.LANGUAGE}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.LANGUAGE}</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  {permissions.some(
                    (item) =>
                      item.keyword === "settingsFaq" && item.isSearch === true
                  ) && (
                    <>
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary mb-3"
                        onClick={handleShow}
                      >
                        <i className="fa fa-plus"></i>
                        {STRING_CONSTANTS.ADD_LANGUAGE}
                      </button>
                    </>
                  )}

                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      width: "200px",
                    }}
                  >
                    {permissions.some(
                      (item) =>
                        item.keyword === "settingsFaq" && item.isSearch === true
                    ) && (
                      <>
                        <IoSearch
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Search Language"
                          className="form-control"
                          onChange={handleSearch}
                          style={{
                            paddingLeft: "30px",
                          }}
                        />
                      </>
                    )}
                  </div>

                  <Modal
                    open={show}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropProps={{
                      style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the last value (0.5) to change the opacity
                      },
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disableBackdropClick
                  >
                    <Fade in={show}>
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 1,
                          p: 4,
                          borderRadius: 2,
                          width: "60%",
                          marginBottom: "50px",
                        }}
                      >
                        <h3>{STRING_CONSTANTS.ADD_LANGUAGE}</h3>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Language"
                                variant="outlined"
                                value={formData.language}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    language: e.target.value,
                                  })
                                }
                                placeholder={STRING_CONSTANTS.LANGUAGE}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Code"
                                variant="outlined"
                                value={formData.code}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    code: e.target.value,
                                  })
                                }
                                placeholder={STRING_CONSTANTS.ENTER_CODE}
                                required
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="flex-end"
                            style={{ marginTop: "40px", marginBottom: "20px" }}
                          >
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleClose}
                              style={{ marginRight: "10px" }}
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{ backgroundColor: "#769A0F" }}
                            >
                              {STRING_CONSTANTS.SUBMIT}
                            </Button>
                          </Grid>
                        </form>
                      </Box>
                    </Fade>
                  </Modal>

                  <Modal
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    closeAfterTransition
                    BackdropProps={{
                      style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disableBackdropClick
                  >
                    <Fade in={showEditModal}>
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 1,
                          p: 4,
                          borderRadius: 2,
                          width: "60%",
                          marginBottom: "50px",
                        }}
                      >
                        <h3>{STRING_CONSTANTS.EDIT_LANGUAGE}</h3>
                        <form onSubmit={handleEditSubmit}>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Language"
                                variant="outlined"
                                value={formData.language}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    language: e.target.value,
                                  })
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Code"
                                variant="outlined"
                                value={formData.code}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    code: e.target.value,
                                  })
                                }
                                required
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="flex-end"
                            style={{ marginTop: "40px", marginBottom: "20px" }}
                          >
                            <Button
                              onClick={() => setShowEditModal(false)}
                              variant="outlined"
                              color="error"
                              style={{ marginRight: "10px" }}
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{ backgroundColor: "#769A0F" }}
                            >
                              {STRING_CONSTANTS.UPDATE}
                            </Button>
                          </Grid>
                        </form>
                      </Box>
                    </Fade>
                  </Modal>
                  {noData ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {STRING_CONSTANTS.NO_LANGUAGE_AVAILABLE}
                    </div>
                  ) : (
                    <div style={{ width: "98%" }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {STRING_CONSTANTS.LANGUAGE}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {STRING_CONSTANTS.CODE}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {STRING_CONSTANTS.STATUS}
                              </TableCell>
                              <TableCell width="80px" align="left">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell width="80px" align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {item.language}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {item.code}
                                </TableCell>
                                <TableCell>
                                  {item.status === "active" ? (
                                    <span className="btn-sm btn-success">
                                      {STRING_CONSTANTS.ACTIVE}
                                    </span>
                                  ) : (
                                    <span className="btn-sm btn-danger">
                                      {STRING_CONSTANTS.INACTIVE}
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell width="80px" align="left">
                                  {actionFormatter(item)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
